import React, { MouseEvent, memo, useState } from "react";
import classnames from "classnames";

// components
import Spinner from "../spinner/Spinner";

// consts
import { SIZES } from "./Button.consts";

// styles
import styles from "./Button.module.scss";

interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "prefix"> {
  fontSize?: keyof typeof SIZES;
  size?: "small" | "medium";
  width?: "full" | "auto";
  variant?: "default" | "outline";
  side?: "client" | "admin";
  color?: "primary" | "secondary";
  iconOrientation?: "start" | "end";
  icon?: React.ReactNode;
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = memo(
  ({
    children,
    fontSize = "large",
    size = "medium",
    width = "full",
    variant = "default",
    side = "client",
    color = "primary",
    iconOrientation = "end",
    icon,
    loading = false,
    className,
    ...props
  }) => {
    const [isPromiseLoading, setIsLoading] = useState(false);

    const buttonHandler = async (e: MouseEvent<HTMLButtonElement>) => {
      if (typeof props.onClick === "function" && !props.form) {
        const handlerValue: unknown = props.onClick(e);
        if (handlerValue instanceof Promise) {
          setIsLoading(true);
          await handlerValue;
          setIsLoading(false);
        }
      }
    };

    const isLoading = isPromiseLoading || loading;

    return (
      <button
        {...props}
        className={classnames(
          styles.button,
          styles[variant],
          styles[side],
          styles[size],
          styles[width],
          styles[color],
          className
        )}
        disabled={props.disabled || isLoading}
        onClick={buttonHandler}
      >
        <>
          {iconOrientation === "start" && icon}
          <span color={"white"}>{children}</span>
          {iconOrientation === "end" && icon}
          {isLoading && (
            <Spinner color={"white"} size="tiny" className={styles.spinner} />
          )}
        </>
      </button>
    );
  }
);

export default Button;
