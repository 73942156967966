import { useCallback, useContext, useEffect, useMemo, useState } from "react";

// context
import { errorContext } from "../../../context/error/ErrorProvider";
import { userContext } from "../../../context/user-provider/UserProvider";
import { localesContext } from "../../../context/local-provider/LocalProvider";

// types
import { ChangeUserRoleType } from "../../../context/user-provider/UserProvider.types";
import { INIT_USER_FORM_DATA } from "./UserAdminScreen.consts";

//translation
import { t } from "@lingui/macro";

export function useUsersAdminForm() {
  const { i18n } = useContext(localesContext);
  const { error, success } = useContext(errorContext);
  const { users, updateUserById } = useContext(userContext);
  const [userFormData, setUserFormData] =
    useState<ChangeUserRoleType>(INIT_USER_FORM_DATA);

  const handleChangeUserData = useCallback(
    (newValue: unknown, type: "email_id" | "role") => {
      // TODO  Fix the react select types and remove this "as"
      const option = newValue as { label: string; value: string };

      setUserFormData((prev) => ({
        ...prev,
        [type]: option.value,
      }));
    },
    [setUserFormData]
  );

  const onSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      try {
        await updateUserById(userFormData.email_id, userFormData.role);
        success(t(i18n)`The role has been successfully changed.`);
      } catch (e) {
        error(e);
      }
    },
    [userFormData, updateUserById, success, error]
  );

  const usersEmailsOptions = useMemo(() => {
    if (users) {
      const emailOptions = users.map((user) => ({
        label: user.email,
        value: user.id,
      }));

      return emailOptions;
    }

    return [{ label: "", value: "" }];
  }, [users]);

  const profileRoleOptions = useMemo(
    () => [
      { label: t(i18n)`User`, value: "user" },
      { label: t(i18n)`Admin`, value: "admin" },
    ],
    []
  );

  return {
    userFormData,
    usersEmailsOptions,
    profileRoleOptions,
    handleChangeUserData,
    onSubmit,
  };
}

export function useUsersAdminFetch() {
  const { error } = useContext(errorContext);
  const { getUsers } = useContext(userContext);

  const [isUsersLoading, setIsUsersLoading] = useState(false);

  const usersFetch = async () => {
    try {
      setIsUsersLoading(true);

      await getUsers();
    } catch (err) {
      error(err);
    } finally {
      setIsUsersLoading(false);
    }
  };

  useEffect(() => {
    usersFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isUsersLoading };
}
