import { useCallback, useContext, useEffect, useMemo, useState } from "react";

// contexts
import { currencyContext } from "../../context/currency-provider/CurrencyProvider";
import { cartContext } from "../../context/cart-provider/CartProvider";

export function useHeaderForm() {
  const { getCurrency, setCurrentCurrency } = useContext(currencyContext);

  const [currencyData, setCurrencyData] = useState<
    { label: string; value: string }[]
  >([]);

  const handleChangeSelectData = useCallback((newValue: unknown) => {
    const option = newValue as { label: string; value: string };

    setCurrencyData([option]);
    setCurrentCurrency(option.value);

    window.location.reload();
  }, []);

  const currencyOptions = useMemo(
    () => [
      { label: "EUR", value: "EUR" },
      { label: "USD", value: "USD" },
      { label: "GBP", value: "GBP" },
      { label: "UAH", value: "UAH" },
    ],
    []
  );

  useEffect(() => {
    const currency = getCurrency();

    setCurrencyData([{ label: currency, value: currency }]);
  }, []);

  return {
    currencyData,
    currencyOptions,
    handleChangeSelectData,
  };
}

export function useHeaderFetch() {
  const { getCartProducts, cartCount } = useContext(cartContext);

  const [isOpenPreviewCart, setIsOpenPreviewCart] = useState(false);
  const [isOpenCartModal, setIsOpenCartModal] = useState(false);

  const handleOpenCartModal = useCallback(() => {
    setIsOpenCartModal(true);
    setIsOpenPreviewCart(false);
  }, [setIsOpenCartModal]);

  useEffect(() => {
    getCartProducts();
  }, [isOpenPreviewCart, isOpenCartModal, cartCount]);

  return {
    isOpenPreviewCart,
    isOpenCartModal,
    cartCount,
    handleOpenCartModal,
    setIsOpenPreviewCart,
    setIsOpenCartModal,
  };
}
