import { format } from "date-fns";

/**
 * Function for formatting a date in the desired format.
 * @param {string} date - A string representing the date in the format "2024-02-01T13:39:15.672Z".
 * @param {boolean} [hours=true] - Optional boolean indicating if hours should be included in the formatted date.
 * @returns {string} - Formatted date as a string.
 */
export const formattedDate = (
  date: string | undefined,
  hours: boolean = true
): string => {
  if (date) {
    const inputDate = new Date(date);

    if (hours) {
      return format(inputDate, "dd/MM/yyyy HH:mm");
    }
    return format(inputDate, "dd/MM/yyyy");
  }

  return "";
};
