// consts
import { PATHS } from "../../route/route.controls";

//context
import { useContext } from "react";
import { localesContext } from "../../context/local-provider/LocalProvider";

//translation
import { t } from "@lingui/macro";

export function useHeaderConsts() {
  const { i18n } = useContext(localesContext);

  const guestNavigationsLinks = [
    {
      title: t(i18n)`Home`,
      to: PATHS.index,
    },
    {
      title: t(i18n)`Membership`,
      to: PATHS.membership,
    },
    {
      title: t(i18n)`Courses`,
      to: PATHS.courseOverview,
    },
    {
      title: t(i18n)`Blog`,
      to: PATHS.blog,
    },
    {
      title: t(i18n)`About us`,
      to: PATHS.aboutUs,
    },
    // {
    //   title: "Forum",
    //   to: "forum",
    // },
  ];

  const userNavigationLinks = [
    {
      title: t(i18n)`Profile`,
      to: PATHS.profile,
    },
    {
      title: t(i18n)`My Courses`,
      to: PATHS.myCourses,
    },
  ];

  return { guestNavigationsLinks, userNavigationLinks };
}
