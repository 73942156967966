import React from "react";

type CloseIconProps = {
  className?: string;
  color?: string;
  width?: number;
  height?: number;
};

const CloseIcon = ({
  className,
  color = "#000",
  width = 13,
  height = 14,
}: CloseIconProps) => {
  return (
    <svg
      className={className}
      viewBox="0 0 13 14"
      fill={color}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1083 10.316L7.79233 6.99995L11.1083 3.68394C11.4664 3.32593 11.4664 2.74956 11.1083 2.39155C10.7503 2.03354 10.174 2.03354 9.81597 2.39155L6.49995 5.70757L3.18394 2.39155C2.82593 2.03354 2.24956 2.03354 1.89155 2.39155C1.53354 2.74956 1.53354 3.32593 1.89155 3.68394L5.20757 6.99995L1.89155 10.316C1.53354 10.674 1.53354 11.2503 1.89155 11.6083C2.24956 11.9664 2.82593 11.9664 3.18394 11.6083L6.49995 8.29233L9.81597 11.6083C10.174 11.9664 10.7503 11.9664 11.1083 11.6083C11.4638 11.2503 11.4638 10.6714 11.1083 10.316Z"
        fill={color}
      />
    </svg>
  );
};
export { CloseIcon };
