import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// hooks
import { useCoursesAdminScreenConsts } from "../use-courses-admin-screen-consts";

// context
import { errorContext } from "../../../../context/error/ErrorProvider";
import { courseContext } from "../../../../context/course-provider/CourseProvider";
import { storageContext } from "../../../../context/storage-provider/StorageProvider";
import { localesContext } from "../../../../context/local-provider/LocalProvider";

// types
import type {
  AddVideoFormType,
  VideoType,
} from "../../../../context/course-provider/CourseProvider.types";
import type { FileType } from "../../../../context/storage-provider/StorageProvider.types";

//translation
import { t } from "@lingui/macro";

export function useVideoAdminForm(
  courseId: string,
  modalType?: "create" | "edit" | null,
  video?: VideoType | null,
  type?: "admin" | "guest"
) {
  const { i18n } = useContext(localesContext);
  const { error, success } = useContext(errorContext);
  const { addVideo, updateVideo, deleteVideo } = useContext(courseContext);
  const { uploadFile, uploadImage, getFileByKey } = useContext(storageContext);

  const { INIT_VIDEO_ADMIN_FORM_DATA } = useCoursesAdminScreenConsts();

  const videoInputRef = useRef<any>(null);
  const fileInputRef = useRef<any>(null);
  const subtitlesInputRef = useRef<any>(null);

  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [isSubtitleUploading, setIsSubtitleUploading] = useState(false);

  const [videoFormData, setVideoFormData] = useState<AddVideoFormType>(
    INIT_VIDEO_ADMIN_FORM_DATA
  );
  const [videoSource, setVideoSource] = useState<FileType | null>(null);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  const getVideoSource = async (key: string, signal: AbortSignal) => {
    const videoBlob = await getFileByKey(key, signal);
    return videoBlob;
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchVideo = async () => {
      try {
        if (videoSource?.key && type === "admin" && modalType) {
          setIsVideoLoading(true);

          const videoBlob = await getVideoSource(videoSource.key, signal);

          if (videoBlob) {
            const url = URL.createObjectURL(videoBlob);
            setVideoUrl(url);
          }
        }
      } catch (e) {
        error(e);
      } finally {
        setIsVideoLoading(false);
      }
    };

    fetchVideo();

    return () => {
      controller.abort();
    };
  }, [modalType, videoSource, type]);

  useEffect(() => {
    if (video && modalType === "edit") {
      const {
        title,
        description,
        source,
        subtitles,
        picture,
        price,
        discountPrice,
        isFree,
      } = video;

      setVideoFormData((prev) => ({
        ...prev,
        title,
        description,
        source: source ? source : prev.source,
        subtitles,
        picture: picture,
        price: price ? String(price) : "",
        discountPrice: discountPrice ? String(discountPrice) : "",
        isFree,
      }));

      if (source) {
        setVideoSource(source);
      }
    }
  }, [setVideoFormData, video, modalType]);

  const handleChangeVideoAdminData = useCallback(
    async (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      try {
        const { name, value } = e.target;

        if (name === "picture") {
          const file = fileInputRef.current.files[0];

          const allowedTypes = ["image/png", "image/jpeg"];

          if (!allowedTypes.includes(file.type)) {
            return error(
              t(
                i18n
              )`The file type is incorrect. Please upload a PNG or JPEG file.`
            );
          }

          if (file.size > 2200000) {
            return error(
              t(
                i18n
              )`The image size is too large. Please try using a different image under 2MB`
            );
          }

          setIsImageUploading(true);
          const result = await uploadImage(file, "video");

          if (result) {
            return setVideoFormData((prev) => ({
              ...prev,
              [name]: result,
            }));
          }
        } else if (name === "subtitles") {
          const subtitleFile = subtitlesInputRef.current.files[0];
          const allowedTypes = ["text/vtt"];

          if (!allowedTypes.includes(subtitleFile.type)) {
            return error(
              t(i18n)`The file type is incorrect. Please upload a VTT file.`
            );
          }

          setIsSubtitleUploading(true);

          const result = await uploadFile(subtitleFile);

          if (result) {
            const file = {
              ...result.file,
              createdAt: String(result.file.createdAt),
              fileSize: String(result.file.fileSize),
            };

            return setVideoFormData((prev) => ({
              ...prev,
              [name]: [file],
            }));
          }
        }

        setVideoFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      } catch (e) {
        error(e);
      } finally {
        setIsImageUploading(false);
        setIsSubtitleUploading(false);
      }
    },
    [setVideoFormData, error]
  );

  const handleChangeVideoSelectAdminData = useCallback(
    (newValue: unknown) => {
      // TODO  Fix the react select types and remove this "as"
      const option = newValue as { label: string; value: boolean };

      setVideoFormData((prev) => ({
        ...prev,
        isFree: option.value,
      }));
    },
    [setVideoFormData]
  );

  const onSubmit = useCallback(
    async (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      try {
        e.preventDefault();

        if (video && modalType === "edit") {
          // edit

          await updateVideo(video.id, {
            ...videoFormData,
            price: videoFormData.price ? videoFormData.price : null,
            discountPrice: videoFormData.discountPrice
              ? videoFormData.discountPrice
              : null,
            courseId,
            picture: videoFormData.picture.split("/").pop() || "",
          });

          success(t(i18n)`The video has been successfully edited.`);

          return null;
        } else {
          // create
          await addVideo({
            ...videoFormData,
            price: videoFormData.price ? videoFormData.price : null,
            discountPrice: videoFormData.discountPrice
              ? videoFormData.discountPrice
              : null,
            courseId,
            picture: videoFormData.picture.split("/").pop() || "",
          });

          success(t(i18n)`The video has been successfully created.`);
        }
      } catch (e) {
        error(e);
      }
    },
    [videoFormData, error, success]
  );

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = videoInputRef.current.files[0] as File;

      if (file.size > 120000000) {
        return error(t(i18n)`Large file size. Please use files under 110 Mb`);
      }

      const source = await uploadFile(file);

      if (source) setVideoSource(source.file);

      if (source) {
        setVideoFormData((prev) => ({ ...prev, source: source.file }));
      }

      success(t(i18n)`File was succsessfully uploaded`);
    } catch (e) {
      error(e);
    }
  };

  const handleRemoveVideo = useCallback(
    async (videoId?: string) => {
      try {
        if (videoId) {
          await deleteVideo(videoId, courseId);
          success(t(i18n)`The video has been successfully deleted`);
        }
      } catch (e) {
        throw e;
      }
    },
    [deleteVideo, success, courseId]
  );

  const handleCloseModal = () => {
    setVideoFormData(INIT_VIDEO_ADMIN_FORM_DATA);
    setVideoUrl(null);
  };

  const freeVideoOptions = useMemo(() => {
    return [
      { label: t(i18n)`Free`, value: true },
      { label: t(i18n)`Not Free`, value: false },
    ];
  }, [i18n.locale]);

  const isDisabledButton = useMemo(
    () =>
      (videoFormData.discountPrice && !videoFormData.price) ||
      !videoFormData.title ||
      !videoFormData.description ||
      !videoFormData.source,
    [videoFormData]
  );

  return {
    videoFormData,
    videoSource,
    videoUrl,
    freeVideoOptions,
    videoInputRef,
    fileInputRef,
    subtitlesInputRef,
    isDisabledButton,
    isVideoLoading,
    isImageUploading,
    isSubtitleUploading,
    handleChangeVideoAdminData,
    handleChangeVideoSelectAdminData,
    handleUpload,
    handleRemoveVideo,
    handleCloseModal,
    onSubmit,
  };
}
