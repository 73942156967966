import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// context
import { errorContext } from "../../../context/error/ErrorProvider";
import { blogContext } from "../../../context/blog-provider/BlogProvider";

export function useCurrentBlogFetch() {
  const { error } = useContext(errorContext);
  const { getBlogById } = useContext(blogContext);

  const params = useParams();

  const [isBlogLoading, setIsBlogLoading] = useState(false);

  const blogFetch = async () => {
    try {
      if (params.blogId) {
        setIsBlogLoading(true);

        await getBlogById(params.blogId);
      }
    } catch (err) {
      error(err);
    } finally {
      setIsBlogLoading(false);
    }
  };

  useEffect(() => {
    blogFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return {
    isBlogLoading,
  };
}
