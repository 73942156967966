import React, { useContext } from "react";

// components
import Input from "../../../../components/input/Input";
import Button from "../../../../components/button/Button";
import Textarea from "../../../../components/textarea/Textarea";

//context
import { localesContext } from "../../../../context/local-provider/LocalProvider";

// hooks
import { useQuestionsModalForm } from "./use-questions-modal-from";

// styles
import styles from "./QuestionsModal.module.scss";

//translation
import { t } from "@lingui/macro";
import { Trans } from "@lingui/macro";

export default function QuestionsModal() {
  const { i18n } = useContext(localesContext);

  const {
    feedbackFormData,
    isDisabledSubmitButton,
    handleChangeFeedbackData,
    onSubmit,
  } = useQuestionsModalForm();

  return (
    <form className={styles.modalContainer}>
      <div className={styles.modalTitle}>
        <h3>
          <Trans>Got any questions?</Trans>
        </h3>
        <p>
          <Trans>
            Let us know and we will contact you as soon as possible.
          </Trans>
        </p>
      </div>

      <div className={styles.formWrapper}>
        <Input
          placeholder={t(i18n)`Your email`}
          name="email"
          value={feedbackFormData.email}
          onChange={handleChangeFeedbackData}
          className={styles.input}
        />
        <Input
          placeholder={t(i18n)`Subject`}
          name="subject"
          value={feedbackFormData.subject}
          onChange={handleChangeFeedbackData}
          className={styles.input}
        />
        <Textarea
          id={"feedback_message"}
          name={"message"}
          placeholder={t(i18n)`Your message`}
          value={feedbackFormData.message}
          onChange={handleChangeFeedbackData}
        />
      </div>

      <div className={styles.buttonWrapper}>
        <Button
          onClick={(e) => onSubmit(e)}
          type="submit"
          color="secondary"
          width="auto"
          disabled={isDisabledSubmitButton}>
          <Trans>Send</Trans>
        </Button>
      </div>
    </form>
  );
}
