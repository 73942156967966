import React, { useCallback, useContext, useState } from "react";

// components
import Spinner from "../../../components/spinner/Spinner";
import { BlogListItem } from "../../general/blog-screen/blog-list-item/BlogListItem";
import Button from "../../../components/button/Button";
import { BlogAdminModal } from "./blog-admin-modal/BlogAdminModal";

// context
import { blogContext } from "../../../context/blog-provider/BlogProvider";

// hooks
import { useBlogAdminFetch } from "./use-blog-admin";

// icons
import { PlusIcon } from "../../../assets/icons/PlusIcon";

// types
import type { BlogType } from "../../../context/blog-provider/BlogProvider.types";

// styles
import styles from "./BlogAdminScreen.module.scss";

//translation
import { Trans } from "@lingui/macro";

export function BlogAdminScreen() {
  const { blogsData } = useContext(blogContext);

  const { isBlogLoading } = useBlogAdminFetch();

  const [isOpenBlogModal, setIsOpenBlogModal] = useState<
    "create" | "edit" | null
  >(null);
  const [selectedBlog, setSelectedBlog] = useState<BlogType | null>(null);

  const handleOpenCreatingModal = useCallback(() => {
    setIsOpenBlogModal("create");
  }, [setIsOpenBlogModal]);

  const handleOpenEditingModal = (blog: BlogType) => {
    setIsOpenBlogModal("edit");
    setSelectedBlog(blog);
  };

  return (
    <section className={styles.blogSection}>
      {isBlogLoading ? (
        <div className={styles.loader}>
          <Spinner />
        </div>
      ) : blogsData && blogsData.length ? (
        <>
          <div className={styles.header}>
            <h6 className={styles.title}>
              <Trans>Blog posts</Trans>
            </h6>

            <Button
              icon={<PlusIcon />}
              iconOrientation="start"
              variant="outline"
              className={styles.addingButton}
              width="auto"
              onClick={handleOpenCreatingModal}>
              <Trans>Add Article</Trans>
            </Button>
          </div>
          <div className={styles.blogItemsWrapper}>
            {blogsData.map((blog) => (
              <BlogListItem
                key={blog.id}
                type="admin"
                blog={blog}
                handleOpenEditingModal={handleOpenEditingModal}
              />
            ))}
          </div>
        </>
      ) : (
        <div className={styles.emptyWrapper}>
          <div className={styles.emptyTextWrapper}>
            <p>
              <Trans>You do not have any article yet.</Trans>{" "}
            </p>
            <p><Trans>Add an article and start your blog today!</Trans></p>
          </div>

          <Button
            icon={<PlusIcon />}
            iconOrientation="start"
            variant="outline"
            className={styles.addingButton}
            width="auto"
            onClick={handleOpenCreatingModal}>
            <Trans>Add Article</Trans>
          </Button>
        </div>
      )}

      <BlogAdminModal
        isOpen={isOpenBlogModal}
        onClose={() => setIsOpenBlogModal(null)}
        blog={selectedBlog}
      />
    </section>
  );
}
