import { z } from "zod";

export const paymentSchema = z.object({
  id: z.string(),
  url: z.string(),
  amount: z.string(),
  status: z.enum([
    "expired",
    "failed",
    "initial",
    "processing",
    "reversed",
    "success",
    "verification",
  ]),
  type: z.enum(["chargeback", "refill"]),
  comment: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const refillSchema = z.object({
  id: z.string(),
  url: z.string(),
  amount: z.string(),
  status: z.enum([
    "expired",
    "failed",
    "initial",
    "processing",
    "reversed",
    "success",
    "verification",
  ]),
  type: z.enum(["chargeback", "refill", "checkout"]),
  comment: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const allPaymentsSchema = z.object({
  total: z.number(),
  data: z.array(paymentSchema),
});

export const paymentFormSchema = z.object({
  id: z.string(),
  orderId: z.string(),
  amount: z.number(),
  currencyCode: z.enum(["EUR", "USD", "GBP"]),
  status: z.enum([
    "expired",
    "failed",
    "initial",
    "processing",
    "reversed",
    "success",
    "verification",
  ]),
});
