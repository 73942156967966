import React, { SVGProps } from "react";

const PlusIcon = ({
  className,
  color = "#E8E8E8",
  width = 24,
  height = 25,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.5656 11H13.5V4.93437C13.5 4.14219 12.8297 3.5 12 3.5C11.1703 3.5 10.5 4.14219 10.5 4.93437V11H4.43437C3.64219 11 3 11.6703 3 12.5C3 13.3297 3.64219 14 4.43437 14H10.5V20.0656C10.5 20.8578 11.1703 21.5 12 21.5C12.8297 21.5 13.5 20.8578 13.5 20.0656V14H19.5656C20.3578 14 21 13.3297 21 12.5C21 11.6703 20.3578 11 19.5656 11Z"
        fill={color}
      />
    </svg>
  );
};
export { PlusIcon };
