// images
import courses_overview_url from "../../../assets/images/courses_overview_bg.png";
import vip_courses_url from "../../../assets/images/vip_courses_bg.png";

// hooks
import { useCoursesMenuFetch } from "../../../components/header/courses-menu/use-courses-menu";

// context

// components
import { CourseMenuItem } from "../../../components/header/course-menu-item/CourseMenuItem";

// styles
import styles from "./CoursesOverview.screen.module.scss";
import { Reviews } from "../main-screen/reviews/Reviews";
import Spinner from "../../../components/spinner/Spinner";

//translation
import { Trans } from "@lingui/macro";

export function CourseOverview() {
  const {
    isCoursesLoading,
    beginnerCoursesData,
    proCoursesData,
    vipCoursesData,
  } = useCoursesMenuFetch();

  return (
    <div>
      {beginnerCoursesData?.length > 0 ? (
        <section className={styles.begginerSection}>
          <img
            src={courses_overview_url}
            className={styles.begginerImageBg}
            alt="courses-overview"
          />
          <div className={styles.infoWrapper}>
            <h2 className={styles.courseTitle}>
              <Trans>Beginner courses</Trans>
            </h2>
            <p className={styles.courseDescription}>
              <Trans>
                The Beginner Course teaches you Blackjack rules and terms. Also,
                you will learn how to win. It shows you how to manage your money
                when playing. You can play Blackjack safely and with confidence.
              </Trans>
            </p>
          </div>
          <div className={styles.courseWrapper}>
            {beginnerCoursesData.map((course: any) => (
              <CourseMenuItem key={course.id} course={course} />
            ))}
          </div>
        </section>
      ) : null}

      {proCoursesData?.length > 0 ? (
        <section className={styles.begginerSection}>
          <div className={styles.infoWrapper}>
            <h2 className={styles.courseTitle}>
              <Trans>Pro courses</Trans>
            </h2>
            <p className={styles.courseDescription}>
              <Trans>
                The Pro Course is for expert players wanting to improve. You
                will learn how professionals play and beat their opponents. You
                will also glimpse different kinds of Blackjack, for instance,
                European and Spanish versions. This course gives you a leg up in
                the game.
              </Trans>
            </p>
          </div>
          <div className={styles.courseWrapper}>
            {proCoursesData.map((course) => (
              <CourseMenuItem key={course.id} course={course} />
            ))}
          </div>
        </section>
      ) : null}

      {vipCoursesData?.length > 0 ? (
        <section className={styles.begginerSection}>
          <img
            src={vip_courses_url}
            className={styles.vipImageBg}
            alt="vip-courses"
          />
          <div className={styles.infoWrapper}>
            <h2 className={styles.courseTitle}>
              <Trans>Vip courses</Trans>
            </h2>
            <p className={styles.courseDescription}>
              <Trans>
                This course is for the players who want to be the best at
                Blackjack. Learn to control your feelings and get tips from the
                pros. Are you ready for big tournaments? Only the best
                strategies are taught in this course.
              </Trans>
            </p>
          </div>
          <div className={styles.courseWrapper}>
            {vipCoursesData.map((course) => (
              <CourseMenuItem key={course.id} course={course} />
            ))}
          </div>
        </section>
      ) : null}

      <div className={styles.reviewsWrap}>
        <Reviews />
      </div>
    </div>
  );
}
