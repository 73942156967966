import React, { useContext } from "react";

// hooks
import { useCourseFetch } from "./use-course";

// context
import { courseContext } from "../../../context/course-provider/CourseProvider";
import { localesContext } from "../../../context/local-provider/LocalProvider";

// compoents
import Spinner from "../../../components/spinner/Spinner";
import { CourseInfo } from "./course-info/CourseInfo";
import { CourseBenefits } from "./course-benefits/CourseBenefits";
import { CourseVideos } from "./course-videos/CourseVideos";
import { Reviews } from "../main-screen/reviews/Reviews";
import { Questions } from "../main-screen/questions/Questions";

// styles
import styles from "./Course.screen.module.scss";

//translation
import { t } from "@lingui/macro";
import { Trans } from "@lingui/macro";

export function CourseScreen() {
  const { isCourseLoading } = useCourseFetch();

  const { i18n } = useContext(localesContext);
  const { courseById } = useContext(courseContext);

  return (
    <div className={styles.container}>
      {isCourseLoading ? (
        <div className={styles.courseLoader}>
          <Spinner size="large" />
        </div>
      ) : courseById ? (
        <>
          <CourseInfo course={courseById} />

          <div className={styles.benefitsWrapper}>
            {courseById.willLearn && courseById.willLearn.length ? (
              <CourseBenefits
                title={t(i18n)`You’ll Learn:`}
                benefits={courseById.willLearn}
              />
            ) : null}

            {courseById.willGet && courseById.willGet.length ? (
              <CourseBenefits
                title={t(i18n)`You’ll Get:`}
                benefits={courseById.willGet}
              />
            ) : null}
          </div>

          <CourseVideos course={courseById} />
        </>
      ) : (
        <p>
          <Trans>
            Unfortunately, there is no information about this course.
          </Trans>
        </p>
      )}

      <Reviews />

      <Questions />
    </div>
  );
}
