import React, { SVGProps } from "react";

const FailedIcon = ({
  className,
  color = "#FDFDFD",
  width = 61,
  height = 61,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      viewBox="0 0 61 61"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="30.5" cy="30.3652" r="30" fill="#981A17" />
      <rect
        x="41.9023"
        y="12.1729"
        width="10"
        height="40"
        rx="4"
        transform="rotate(46.0952 41.9023 12.1729)"
        fill={color}
      />
      <rect
        x="13.4873"
        y="18.7017"
        width="10"
        height="40"
        rx="4"
        transform="rotate(-43.9048 13.4873 18.7017)"
        fill={color}
      />
    </svg>
  );
};
export { FailedIcon };
