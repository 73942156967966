import { useContext, useEffect, useState } from "react";

// context
import { errorContext } from "../../../../context/error/ErrorProvider";
import { userContext } from "../../../../context/user-provider/UserProvider";
import { membershipContext } from "../../../../context/membership-provider/MembershipProvider";
import { currencyContext } from "../../../../context/currency-provider/CurrencyProvider";
import { localesContext } from "../../../../context/local-provider/LocalProvider";

// helpers
import { formattedDate } from "../../../../helpers/format-date";
import { getMembershipTitle } from "../../../../helpers/get-membership-title";

// components
import Spinner from "../../../../components/spinner/Spinner";

// types
import type { OrderType } from "../../../../context/order-provider/OrderProvider.types";

// styles
import styles from "./OrderHistory.module.scss";

//translation
import { t } from "@lingui/macro";
import { Trans } from "@lingui/macro";

export function OrderHistory() {
  const { i18n } = useContext(localesContext);
  const { error } = useContext(errorContext);
  const { userData } = useContext(userContext);
  const { getMembershipTypeById } = useContext(membershipContext);
  const { getCurrencySymbol } = useContext(currencyContext);

  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  const [ordersData, setOrdersData] = useState<OrderType[] | null>(null);

  const courseCategory = (category: string) => {
    switch (category) {
      case "beginner":
        return t(i18n)`Beginners courses:`;

      case "pro":
        return t(i18n)`Pro courses:`;

      case "vip":
        return t(i18n)`VIP courses:`;

      default:
        return "";
    }
  };

  useEffect(() => {
    const fetchOrdersData = async () => {
      if (userData) {
        try {
          setIsOrdersLoading(true);
          const updatedUserData = await Promise.all(
            userData.orders.map(async (order) => {
              if (order.membershipTypeId) {
                const membershipResponse = await getMembershipTypeById(
                  order.membershipTypeId,
                );

                return {
                  ...order,
                  membershipTypeId: membershipResponse
                    ? membershipResponse.name
                    : null,
                };
              }
              return order;
            }),
          );

          setOrdersData(updatedUserData);
        } catch (e) {
          error(e);
        } finally {
          setIsOrdersLoading(false);
        }
      }
    };

    fetchOrdersData();
  }, [userData]);

  return (
    <section className={styles.container}>
      <p className={styles.title}>
        <Trans>Order history</Trans>
      </p>
      {isOrdersLoading ? (
        <Spinner />
      ) : (
        <div className={styles.tableWrapper}>
          <div className={styles.tableRowHead}>
            <div>
              <Trans>Type</Trans>
            </div>

            <div>
              <Trans>Amount</Trans>
            </div>

            <div>
              <Trans>Status</Trans>
            </div>

            <div>
              <Trans>Date</Trans>
            </div>
          </div>
          {ordersData ? (
            ordersData
              .sort(
                (a, b) =>
                  new Date(b.createdAt).getTime() -
                  new Date(a.createdAt).getTime(),
              )
              .map((order) => {
                return (
                  <div key={order.id} className={styles.tableRowBody}>
                    <div className={styles.orderItem}>
                      {order.courses?.map((course) => (
                        <div className={styles.rowTitle} key={course.id}>
                          {courseCategory(course.category)} {course.title}
                        </div>
                      ))}

                      {order.videos?.map((video) => (
                        <div className={styles.rowTitle} key={video.id}>
                          <Trans>Videos:</Trans> {video.title}
                        </div>
                      ))}

                      {order.membershipTypeId ? (
                        <div className={styles.rowTitle}>
                          <Trans>Membership:</Trans>{" "}
                          {getMembershipTitle(order.membershipTypeId)}
                        </div>
                      ) : null}
                    </div>

                    <div className={styles.rowPrice}>
                      {getCurrencySymbol()} {order.total}
                    </div>

                    <div className={styles.rowStatus}>{order.status}</div>

                    <div className={styles.rowDate}>
                      {formattedDate(order.updatedAt, false)}
                    </div>
                  </div>
                );
              })
          ) : (
            <div>
              <Trans>
                At the moment, unfortunately, payments are not available.
              </Trans>
            </div>
          )}
        </div>
      )}
    </section>
  );
}
