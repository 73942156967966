import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

// icons
import { PlusIcon } from "../../../assets/icons/PlusIcon";

// components
import Button from "../../../components/button/Button";
import Spinner from "../../../components/spinner/Spinner";
import { CourseAdminItem } from "./course-admin-item/CourseAdminItem";
import { CourseAdminModal } from "./course-admin-modal/CourseAdminModal";

// hooks
import { useCoursesAdminFetch } from "./use-courses-admin";
import { useWindowSize } from "../../../hooks/use-window-size/use-window-size";

// styles
import styles from "./CoursesAdminScreen.module.scss";

//translation
import { Trans } from "@lingui/macro";

export function CoursesAdminScreen() {
  const {
    isCoursesLoading,
    beginnerCoursesData,
    vipCoursesData,
    proCoursesData,
  } = useCoursesAdminFetch();

  const { width } = useWindowSize();

  const [isOpenCourseModal, setIsOpenCourseModal] = useState<
    "create" | "edit" | null
  >(null);
  const [courseType, setCourseType] = useState<
    "beginner" | "pro" | "vip" | null
  >(null);

  const handleCreateNewCourse = useCallback(
    (courseType: "beginner" | "pro" | "vip") => {
      setIsOpenCourseModal("create");
      setCourseType(courseType);
    },
    [],
  );

  return (
    <section className={styles.coursesSection}>
      {isCoursesLoading ? (
        <Spinner />
      ) : (
        <>
          <div className={styles.courseTypeWrapper}>
            <div className={styles.courseHeader}>
              <h6>
                <Trans>Beginner</Trans>
              </h6>

              {width >= 768 ? (
                <Button
                  width="auto"
                  icon={<PlusIcon />}
                  iconOrientation="start"
                  onClick={() => handleCreateNewCourse("beginner")}>
                  <Trans>Add Beginner Course</Trans>
                </Button>
              ) : null}
            </div>

            <div className={styles.coursesList}>
              {beginnerCoursesData.length ? (
                beginnerCoursesData.map((course) => (
                  <CourseAdminItem key={course.id} course={course} />
                ))
              ) : (
                <div>
                  <Trans>
                    Unfortunately, at the moment, there are no available
                    beginner courses.
                  </Trans>
                </div>
              )}
            </div>

            {width < 768 ? (
              <Button
                icon={<PlusIcon />}
                iconOrientation="start"
                onClick={() => handleCreateNewCourse("beginner")}>
                <Trans>Add Beginner Course</Trans>
              </Button>
            ) : null}
          </div>

          <div className={styles.courseTypeWrapper}>
            <div className={styles.courseHeader}>
              <h6>
                <Trans>Pro</Trans>
              </h6>

              {width >= 768 ? (
                <Button
                  width="auto"
                  icon={<PlusIcon />}
                  iconOrientation="start"
                  onClick={() => handleCreateNewCourse("pro")}>
                  <Trans>Add Pro Course</Trans>
                </Button>
              ) : null}
            </div>

            <div className={styles.coursesList}>
              {proCoursesData.length ? (
                proCoursesData.map((course) => (
                  <CourseAdminItem key={course.id} course={course} />
                ))
              ) : (
                <div>
                  <Trans>
                    Unfortunately, at the moment, there are no available PRO
                    courses.
                  </Trans>
                </div>
              )}
            </div>

            {width < 768 ? (
              <Button
                icon={<PlusIcon />}
                iconOrientation="start"
                onClick={() => handleCreateNewCourse("pro")}>
                <Trans>Add Pro Course</Trans>
              </Button>
            ) : null}
          </div>

          <div className={styles.courseTypeWrapper}>
            <div className={styles.courseHeader}>
              <h6>
                <Trans>Vip</Trans>
              </h6>

              {width >= 768 ? (
                <Button
                  width="auto"
                  icon={<PlusIcon />}
                  iconOrientation="start"
                  onClick={() => handleCreateNewCourse("vip")}>
                  <Trans>Add Vip Course</Trans>
                </Button>
              ) : null}
            </div>

            <div className={styles.coursesList}>
              {vipCoursesData.length ? (
                vipCoursesData.map((course) => (
                  <CourseAdminItem key={course.id} course={course} />
                ))
              ) : (
                <div>
                  <Trans>
                    Unfortunately, at the moment, there are no available VIP
                    courses.
                  </Trans>
                </div>
              )}
            </div>

            {width < 768 ? (
              <Button
                icon={<PlusIcon />}
                iconOrientation="start"
                onClick={() => handleCreateNewCourse("vip")}>
                <Trans>Add Vip Course</Trans>
              </Button>
            ) : null}
          </div>
        </>
      )}

      <CourseAdminModal
        isOpen={isOpenCourseModal}
        onClose={() => setIsOpenCourseModal(null)}
        course={null}
        courseType={courseType}
      />
    </section>
  );
}
