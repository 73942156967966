import { setupI18n } from "@lingui/core";
import {
  en as enPlurals,
  uk as ukPlurals,
  fr as frPlurals,
  de as dePlurals,
} from "make-plural/plurals";

// messages
import { messages as translationsEn } from "../../locales/en/messages";
import { messages as translationsUk } from "../../locales/uk/messages";
import { messages as translationsFr } from "../../locales/fr/messages";
import { messages as translationsDe } from "../../locales/de/messages";

// types
import type { Language } from "./LocaleProvider.types";

// const
import { defaultLanguage, languages } from "./LocaleProvider.const";

export function getI18nInstance(language: Language) {
  const i18n = setupI18n({
    locale: language,
    localeData: {
      en: { plurals: enPlurals },
      uk: { plurals: ukPlurals },
      fr: { plurals: frPlurals },
      de: { plurals: dePlurals },
    },
    messages: {
      en: translationsEn,
      uk: translationsUk,
      fr: translationsFr,
      de: translationsDe,
    },
  });
  return i18n;
}

// Function to validate the language
export const validateLanguage = (
  possibleLanguage: string | null | undefined
): Language => {
  if (possibleLanguage === undefined) {
    return defaultLanguage;
  }
  const language = languages.find((lang) => lang.value === possibleLanguage);
  if (language) {
    return language.value;
  }
  throw new Error("No language");
};

export const getFullLanguageTitle = (language: string) => {
  switch (language) {
    case "EN":
      return "English";
    case "UK":
      return "Ukrainian";
    case "FR":
      return "French";
    case "DE":
      return "German";
    default:
      return "English";
  }
};
