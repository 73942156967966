import { useContext, useEffect, useState } from "react";

// context
import { errorContext } from "../../../../context/error/ErrorProvider";

// types
import type { VideoType } from "../../../../context/course-provider/CourseProvider.types";
import { courseContext } from "../../../../context/course-provider/CourseProvider";

export function useVideoFetch(
  video: VideoType,
  isOpen: boolean,
  type?: "admin" | "user" | "guest"
) {
  const { error } = useContext(errorContext);
  const { getCachedVideoById, getSubtitlesUrl } = useContext(courseContext);

  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [subtitlesUrls, setSubtitlesUrls] = useState<string[]>([]);

  const videoFetch = async (video: VideoType) => {
    try {
      if (type !== "guest") {
        setIsVideoLoading(true);

        const videoId = video.id;
        const url = await getCachedVideoById(videoId);

        if (video.subtitles) {
          const subtitlesUrls = (
            await Promise.all(
              video.subtitles.map(
                async (subtitle) => await getSubtitlesUrl(subtitle.key)
              )
            )
          ).filter((s): s is string => s !== null);

          if (subtitlesUrls) {
            setSubtitlesUrls(subtitlesUrls);
          }
        }

        if (url) {
          setVideoUrl(url);
        }
      }
    } catch (err) {
      error(err);
    } finally {
      setIsVideoLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      videoFetch(video);
    }
  }, [video, type, isOpen]);

  return {
    isVideoLoading,
    videoUrl,
    subtitlesUrls,
  };
}
