import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// context
import { errorContext } from "../../../context/error/ErrorProvider";
import { courseContext } from "../../../context/course-provider/CourseProvider";

export function useCourseFetch() {
  const { error } = useContext(errorContext);
  const { getCourseById } = useContext(courseContext);

  const { courseId } = useParams();

  const [isCourseLoading, setIsCourseLoading] = useState(false);

  const courseFetch = async () => {
    try {
      if (courseId) {
        setIsCourseLoading(true);

        await getCourseById(courseId);
      }
    } catch (err) {
      error(err);
    } finally {
      setIsCourseLoading(false);
    }
  };

  useEffect(() => {
    courseFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId]);

  return {
    isCourseLoading,
  };
}
