import React, { SVGProps } from "react";

const ArrowDownIcon = ({
  className,
  color = "#E8E8E8",
  width = 24,
  height = 24,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.94702 9L12.947 15L18.947 9"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export { ArrowDownIcon };
