// types
import type { MembershipTypesType } from "../context/membership-provider/MembershipProvider.types";

/**
 * Function for formatting a date in the desired format.
 * @param {string} date - A string representing the date in the format "2024-02-01T13:39:15.672Z".
 * @returns {string} - Formatted date as a string.
 */

export const sortMemberships = (memberships: MembershipTypesType[]) => {
  const order = {
    ONE_MONTH: 0,
    ONE_YEAR: 1,
    THREE_YEARS: 2,
  };

  return memberships.sort((a, b) => order[a.name] - order[b.name]);
};
