import React, { useCallback, useContext, useState } from "react";

// context
import { blogContext } from "./../../../../context/blog-provider/BlogProvider";
import { errorContext } from "../../../../context/error/ErrorProvider";

export function useLaterNewsForm() {
  const { error } = useContext(errorContext);
  const { getBlogsByNameFilter } = useContext(blogContext);

  const [searchBlogValue, setSearchBlogValue] = useState<string>("");

  const searchFilteredArticles = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      await getBlogsByNameFilter(searchBlogValue);
    },
    [searchBlogValue]
  );

  const handleChangeHeaderData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setSearchBlogValue(value);
    },
    []
  );

  const clearFilteredProducts = async () => {
    try {
      await getBlogsByNameFilter("");

      setSearchBlogValue("");
    } catch (e) {
      error(e);
    }
  };

  return {
    searchBlogValue,
    handleChangeHeaderData,
    searchFilteredArticles,
    clearFilteredProducts,
  };
}
