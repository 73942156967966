import { useContext } from "react";

// icons
import { ArrowLeftIcon } from "../../assets/icons/ArrowLeftIcon";

// contexts
import { localesContext } from "../../context/local-provider/LocalProvider";

// hooks
import { useWindowSize } from "../../hooks/use-window-size/use-window-size";
import { useVideoFetch } from "../../side/client/my-courses-user-screen/video-item/use-video";

// components
import Spinner from "../spinner/Spinner";
import Modal from "../modal/Modal";

// types
import type { VideoType } from "../../context/course-provider/CourseProvider.types";

// styles
import styles from "./VideoModal.module.scss";

// translation
import { t, Trans } from "@lingui/macro";

type VideoModalProps = {
  isOpen: boolean;
  video: VideoType;
  onClose: () => void;
  type: "admin" | "guest" | "user";
};

export function VideoModal({ isOpen, video, type, onClose }: VideoModalProps) {
  const { i18n, locale } = useContext(localesContext);

  const { width } = useWindowSize();

  const { isVideoLoading, videoUrl, subtitlesUrls } = useVideoFetch(
    video,
    isOpen,
    type
  );

  return (
    <Modal
      isOpen={Boolean(isOpen)}
      onClose={onClose}
      header={""}
      size="fullScreen"
      width={width > 767 ? "75%" : "97%"}
      className={styles.modal}
    >
      <div className={styles.videoPlayer}>
        {width < 768 ? (
          <div>
            <ArrowLeftIcon
              width={24}
              height={24}
              onClick={onClose}
              className={styles.backArrow}
            />
          </div>
        ) : null}
        {isVideoLoading ? (
          <div className={styles.videoLoader}>
            <Spinner size="large" />
          </div>
        ) : videoUrl ? (
          <video
            className={styles.videoFrame}
            controls
            controlsList="nodownload"
          >
            <source src={videoUrl || ""} type="video/mp4" />

            {subtitlesUrls.length
              ? subtitlesUrls.map((subtitle) => (
                  <track
                    key={subtitle}
                    label={t(i18n)`Ukrainian`}
                    src={subtitle}
                    kind="subtitles"
                    default={locale === "ua"}
                  />
                ))
              : null}

            <Trans>Your browser does not support the video tag.</Trans>
          </video>
        ) : (
          <div className={styles.emptyVideo}>
            <h6>
              <Trans>Video is unavailable</Trans>
            </h6>
          </div>
        )}
      </div>
    </Modal>
  );
}
