import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import classnames from "classnames";

// hooks
import { useAuth } from "../../../../context/user-provider/use-auth";
import { useWindowSize } from "../../../../hooks/use-window-size/use-window-size";

// images
import straight_src from "../../../../assets/images/straight.png";
import cards_chips_src from "../../../../assets/images/cards_chips.png";

// components
import { LoginModal } from "../login-modal/LoginModal";
import Button from "../../../../components/button/Button";

// consts
import { PATHS } from "../../../../route/route.controls";

//context
import { localesContext } from "../../../../context/local-provider/LocalProvider";

// styles
import styles from "./Introduction.module.scss";

//translation
import { t } from "@lingui/macro";
import { Trans } from "@lingui/macro";

type IntroductionProps = {
  handleNavigateToPlans?: () => void;
};

export function Introduction({ handleNavigateToPlans }: IntroductionProps) {
  const { i18n } = useContext(localesContext);

  const { pathname: currentURL } = useLocation();

  const { isTypeLoginModal, onSubmit, openLoginModal, closeLoginModal } =
    useAuth();

  const isMembershipPage = currentURL === PATHS.membership;

  return (
    <section className={styles.introductionSection}>
      <div
        className={classnames(
          styles.introductionWrapper,
          isMembershipPage ? styles.membershipIntroductionWrapper : "",
        )}>
        <div className={styles.introductionText}>
          <h2 className={styles.introductionTitle}>
            {isMembershipPage ? (
              t(
                i18n,
              )`Choose Your Plan and Learn from Professional Poker Players`
            ) : (
              <>
                <Trans>
                  Learn to Play <br />
                  Poker Game
                </Trans>
              </>
            )}
          </h2>
        </div>
        <div className={styles.descriptionWrapper}>
          <p className={styles.introductionDescription}>
            {isMembershipPage
              ? t(
                  i18n,
                )`Explore our three membership options and gain expert guidance to elevate your poker skills. Select the plan that suits you best and start your journey towards becoming a pro!`
              : t(
                  i18n,
                )`Unlock your potential with our expert courses. Whether you're a beginner or experienced, learn poker strategy, different types of poker, and types of poker hands. Master these techniques to become a champion at the table.`}
          </p>
        </div>

        <div className={styles.buttonWrapper}>
          {isMembershipPage ? (
            <Button
              width="auto"
              onClick={
                handleNavigateToPlans ? handleNavigateToPlans : undefined
              }>
              <Trans>Choose plan</Trans>
            </Button>
          ) : null}
        </div>

        {isMembershipPage ? (
          <div className={styles.membershipImageWrapper}>
            <img src={cards_chips_src} alt="cards and chips" />
          </div>
        ) : (
          <div className={styles.imageWrapper}>
            <img src={straight_src} alt="straight" />
          </div>
        )}
      </div>

      <LoginModal
        isOpen={isTypeLoginModal}
        openLoginModal={openLoginModal}
        onClose={closeLoginModal}
        onSubmit={onSubmit}
      />
    </section>
  );
}
