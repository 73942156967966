import React, { useContext } from "react";
import { useRoutes } from "react-router-dom";

// context
import { sessionContext } from "./context/session-provider/SessionProvider";

// components
import { ScrollToTop } from "./components/scroll-to-top/ScrollToTop";

// routes
import { guestRoutes, userRoutes, adminRoutes } from "./route/routes";

// styles
import styles from "./App.module.scss";

function App() {
  const { getRoleFromToken } = useContext(sessionContext);
  const role = getRoleFromToken();

  const isAdmin = role === "admin";
  const isUser = role === "user";

  const routes = isAdmin ? adminRoutes : isUser ? userRoutes : guestRoutes;
  const routesMain = useRoutes(routes);

  return (
    <div className={styles.container}>
      {routesMain}
      <ScrollToTop />
    </div>
  );
}

export default App;
