/**
 * Returns the title based on the plan name.
 *
 * @param {string} membershipName - The name of the plan.
 * @returns {string} - The title corresponding to the plan name.
 */
//translation
import { Trans } from "@lingui/macro";

export function getMembershipTitle(membershipName: string) {
  if (membershipName === "ONE_MONTH") {
    return (
      <>
        <Trans>Monthly</Trans>
      </>
    );
  }

  if (membershipName === "ONE_YEAR") {
    return (
      <>
        <Trans>Annual</Trans>
      </>
    );
  }

  return (
    <>
      <Trans>3 years plan</Trans>
    </>
  );
}
