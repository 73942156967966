import React, { useCallback, useContext, useState } from "react";

// hooks
import { useWindowSize } from "../../../../hooks/use-window-size/use-window-size";
import { useVideoAdminForm } from "./use-video-admin";

// icons
import { PlusIcon } from "../../../../assets/icons/PlusIcon";
import { DefaultVideoIcon } from "../../../../assets/icons/DefaultVideoIcon";
import { ArrowLeftIcon } from "../../../../assets/icons/ArrowLeftIcon";

// context
import { errorContext } from "../../../../context/error/ErrorProvider";
import { storageContext } from "../../../../context/storage-provider/StorageProvider";
import { localesContext } from "../../../../context/local-provider/LocalProvider";

// components
import Modal from "../../../../components/modal/Modal";
import Button from "../../../../components/button/Button";
import Input from "../../../../components/input/Input";
import Textarea from "../../../../components/textarea/Textarea";
import { VideoModal } from "../../../../components/video-modal/VideoModal";
import Spinner from "../../../../components/spinner/Spinner";

// types
import type { VideoType } from "../../../../context/course-provider/CourseProvider.types";

// styles
import styles from "./VideoAdminModal.module.scss";
import { Select } from "../../../../components/select/select";

//translation
import { t } from "@lingui/macro";
import { Trans } from "@lingui/macro";

type VideoAdminModalProps = {
  courseId: string;
  video?: VideoType | null;
  isOpen: "create" | "edit" | null;
  onClose: () => void;
};

export function VideoAdminModal({
  courseId,
  video,
  isOpen,
  onClose,
}: VideoAdminModalProps) {
  const { i18n } = useContext(localesContext);
  const { error } = useContext(errorContext);
  const { uploadProgress } = useContext(storageContext);
  const { width } = useWindowSize();

  const {
    videoFormData,
    videoUrl,
    freeVideoOptions,
    videoInputRef,
    fileInputRef,
    subtitlesInputRef,
    isDisabledButton,
    isVideoLoading,
    isImageUploading,
    isSubtitleUploading,
    handleChangeVideoAdminData,
    handleChangeVideoSelectAdminData,
    handleUpload,
    handleCloseModal,
    onSubmit,
  } = useVideoAdminForm(courseId, isOpen, video, "admin");

  const [isOpenVideoModal, setIsOpenVideoModal] = useState(false);

  const isUploadingProcess = () => {
    if (videoUrl) {
      return uploadProgress > 0 && uploadProgress < 100 ? uploadProgress : 100;
    }

    return uploadProgress;
  };

  const isCreatingModal = isOpen === "create";

  const modalHeader = isCreatingModal
    ? t(i18n)`Add new video`
    : t(i18n)`Edit video`;

  const closeModal = () => {
    onClose();
    handleCloseModal();
  };

  const handleSubmit = useCallback(
    async (e: any) => {
      try {
        await onSubmit(e);
        closeModal();
      } catch (e) {
        error(e);
      }
    },
    [onSubmit, closeModal, error]
  );

  return (
    <Modal
      isOpen={Boolean(isOpen)}
      onClose={closeModal}
      header={""}
      width={width >= 1024 ? "75%" : width > 768 ? "80%" : "100%"}
      size="fullScreen"
      className={styles.modal}
    >
      <form className={styles.form}>
        <div className={styles.mainContent}>
          <div className={styles.titleWrapper}>
            {width < 768 ? (
              <ArrowLeftIcon
                width={24}
                height={24}
                onClick={closeModal}
                className={styles.backArrow}
              />
            ) : null}

            <p className={styles.title}>{modalHeader}</p>
          </div>

          <div className={styles.videoWrapper}>
            <div className={styles.videoPlayerWrapper}>
              <input
                ref={videoInputRef}
                type="file"
                accept="video/mp4"
                onChange={handleUpload}
                style={{ display: "none" }}
              />
              {isVideoLoading ? (
                <div className={styles.videoLoader}>
                  <Spinner />
                </div>
              ) : videoUrl && uploadProgress ? (
                <div className={styles.video}>
                  <div className={styles.videoPlayer}>
                    <video className={styles.videoFrame}>
                      <source src={videoUrl || ""} type="video/mp4" />
                      <Trans>
                        Your browser does not support the video tag.
                      </Trans>
                    </video>
                  </div>
                  <div className={styles.uploadProgressBarWrapper}>
                    <div
                      className={styles.uploadProgressBar}
                      style={{
                        width: `${isUploadingProcess()}%`,
                      }}
                    ></div>
                  </div>
                </div>
              ) : videoUrl ? (
                <div className={styles.video}>
                  <div className={styles.videoPlayer}>
                    <video className={styles.videoFrame}>
                      <source src={videoUrl || ""} type="video/mp4" />
                      <Trans>
                        Your browser does not support the video tag.
                      </Trans>
                    </video>
                  </div>
                  <Button
                    width="auto"
                    onClick={(e) => {
                      e.preventDefault();
                      videoInputRef.current.click();
                    }}
                    className={styles.uploadButton}
                  >
                    <Trans>Change Video</Trans>
                  </Button>
                </div>
              ) : uploadProgress ? (
                <div className={styles.video}>
                  <div className={styles.iconWrapper}>
                    <DefaultVideoIcon />
                  </div>
                  <div className={styles.uploadProgressBarWrapper}>
                    <div
                      className={styles.uploadProgressBar}
                      style={{
                        width: `${isUploadingProcess()}%`,
                      }}
                    ></div>
                  </div>
                </div>
              ) : (
                <div className={styles.video}>
                  <div className={styles.iconWrapper}>
                    <DefaultVideoIcon />
                  </div>
                  <Button
                    icon={width > 768 ? <PlusIcon /> : ""}
                    iconOrientation="start"
                    width="auto"
                    onClick={(e) => {
                      e.preventDefault();
                      videoInputRef.current.click();
                    }}
                    className={styles.uploadButton}
                  >
                    <Trans>Upload Video</Trans>
                  </Button>
                </div>
              )}
            </div>
            <div className={styles.inputTitleWrapper}>
              <Input
                variant="admin"
                label={t(i18n)`Video title`}
                value={videoFormData.title}
                name="title"
                onChange={handleChangeVideoAdminData}
              />
            </div>
            <div className={styles.priceInputsWrapper}>
              <Input
                variant="admin"
                label={t(i18n)`Video price`}
                value={videoFormData.price || ""}
                name="price"
                onChange={handleChangeVideoAdminData}
              />
              <Input
                variant="admin"
                label={t(i18n)`Discount price`}
                value={videoFormData.discountPrice || ""}
                name="discountPrice"
                onChange={handleChangeVideoAdminData}
              />
              <Select
                placeholder={t(i18n)`Free or not free`}
                options={freeVideoOptions}
                value={freeVideoOptions.find(
                  ({ value }) => value === videoFormData.isFree
                )}
                onChange={(value) => handleChangeVideoSelectAdminData(value)}
                className={styles.select}
              />
            </div>
            <div className={styles.inputDescriptionWrapper}>
              <Textarea
                variant="admin"
                label={t(i18n)`Description`}
                id="video_description"
                value={videoFormData.description}
                name="description"
                onChange={handleChangeVideoAdminData}
                rows={width < 768 ? 10 : 4}
              />
            </div>

            <div className={styles.fileWrapper}>
              <div className={styles.videoScreenWrapper}>
                {isImageUploading ? (
                  <Spinner />
                ) : videoFormData.picture ? (
                  <div className={styles.pictureWrapper}>
                    <input
                      type="file"
                      name="picture"
                      onChange={handleChangeVideoAdminData}
                      ref={fileInputRef}
                      style={{ display: "none" }}
                    />

                    <img src={videoFormData.picture} alt="video screen" />

                    <Button
                      width="auto"
                      variant="outline"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        fileInputRef.current?.click();
                      }}
                      className={styles.videoScreenButton}
                    >
                      <Trans>Change Video Screen</Trans>
                    </Button>
                  </div>
                ) : (
                  <div className={styles.buttonWrapper}>
                    <input
                      type="file"
                      name="picture"
                      onChange={handleChangeVideoAdminData}
                      ref={fileInputRef}
                      style={{ display: "none" }}
                    />

                    <Button
                      width="auto"
                      variant="outline"
                      icon={<PlusIcon />}
                      iconOrientation="start"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        fileInputRef.current?.click();
                      }}
                      className={styles.videoScreenButton}
                    >
                      <Trans>Add Video Screen</Trans>
                    </Button>
                  </div>
                )}
              </div>

              {isSubtitleUploading ? (
                <Spinner />
              ) : videoFormData?.subtitles?.length &&
                videoFormData.subtitles[0].fileName ? (
                <div className={styles.subtitlesWrapper}>
                  <input
                    type="file"
                    name="subtitles"
                    onChange={handleChangeVideoAdminData}
                    ref={subtitlesInputRef}
                    style={{ display: "none" }}
                  />
                  <p>{videoFormData.subtitles[0].fileName}</p>

                  <Button
                    width="auto"
                    variant="outline"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      subtitlesInputRef.current?.click();
                    }}
                    className={styles.videoScreenButton}
                  >
                    <Trans>Change Subtitles</Trans>
                  </Button>
                </div>
              ) : (
                <div className={styles.subtitlesWrapper}>
                  <input
                    type="file"
                    name="subtitles"
                    onChange={handleChangeVideoAdminData}
                    ref={subtitlesInputRef}
                    style={{ display: "none" }}
                  />

                  <Button
                    width="auto"
                    variant="outline"
                    icon={<PlusIcon />}
                    iconOrientation="start"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      subtitlesInputRef.current?.click();
                    }}
                    className={styles.videoScreenButton}
                  >
                    <Trans>Add Subtitles</Trans>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={styles.buttonsWrapper}>
          <Button
            width="auto"
            type="submit"
            onClick={handleSubmit}
            disabled={isDisabledButton}
          >
            <Trans>Save</Trans>
          </Button>
        </div>

        {video ? (
          <VideoModal
            isOpen={isOpenVideoModal}
            onClose={() => {
              setIsOpenVideoModal(false);
            }}
            video={video}
            type="admin"
          />
        ) : null}
      </form>
    </Modal>
  );
}
