import React from "react";

// images
import suits_src from "../../../../assets/images/suits.png";
import arrow_bottom_src from "../../../../assets/images/arrow_bottom_background.png";

// components
import QuestionsModal from "../questions-modal/QuestionsModal";

// styles
import styles from "./Questions.module.scss";

export function Questions() {
  return (
    <section className={styles.questionsSection}>
      <img
        src={arrow_bottom_src}
        alt="arrow"
        className={styles.backgroundArrowImage}
      />
      <div className={styles.questionsWrapper}>
        <div className={styles.backgroundImage}>
          <img src={suits_src} alt="suits of cards" />
        </div>

        <QuestionsModal />
      </div>
    </section>
  );
}
