import { useContext, useEffect, useState } from "react";

// context
import { errorContext } from "../../../context/error/ErrorProvider";
import { membershipContext } from "../../../context/membership-provider/MembershipProvider";

export function useMembershipFetch() {
  const { error } = useContext(errorContext);
  const { getMembershipsTypes } = useContext(membershipContext);

  const [isMembershipsLoading, setIsMembershipsLoading] = useState(false);

  const getMembershipsData = async () => {
    try {
      setIsMembershipsLoading(true);

      await getMembershipsTypes();
    } catch (err) {
      error(err);
    } finally {
      setIsMembershipsLoading(false);
    }
  };

  useEffect(() => {
    getMembershipsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isMembershipsLoading };
}
