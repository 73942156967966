import React, { SVGProps } from "react";

const DefaultPictureIcon = ({
  className,
  color = "#000000",
  width = 30,
  height = 25,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 30 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30 5C30 2.25 27.75 0 25 0H5C2.25 0 0 2.25 0 5V20C0 22.75 2.25 25 5 25H25C27.75 25 30 22.75 30 20V5ZM7.5 5C8.875 5 10 6.125 10 7.5C10 8.875 8.875 10 7.5 10C6.125 10 5 8.875 5 7.5C5 6.125 6.125 5 7.5 5ZM27.5 20C27.5 21.375 26.375 22.5 25 22.5H5.5C4.375 22.5 3.875 21.125 4.625 20.375L9.125 15.875C9.625 15.375 10.375 15.375 10.875 15.875L11.625 16.625C12.125 17.125 12.875 17.125 13.375 16.625L21.625 8.375C22.125 7.875 22.875 7.875 23.375 8.375L27.125 12.125C27.375 12.375 27.5 12.625 27.5 13V20Z"
        fill={color}
        fillOpacity="0.3"
      />
    </svg>
  );
};
export { DefaultPictureIcon };
