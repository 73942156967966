import { Link } from "react-router-dom";
import { useContext } from "react";

// consts
import { PATHS } from "../../route/route.controls";

//context
import { localesContext } from "../../context/local-provider/LocalProvider";

//translation
import { t } from "@lingui/macro";
import { Trans } from "@lingui/macro";

export function useFooterConsts() {
  const { i18n } = useContext(localesContext);

  const footerData = [
    {
      title: t(i18n)`Our address`,
      items: [
        t(i18n)`Zenit Marketing LTD`,
        t(i18n)`85 Great Portland st., 1st floor`,
        t(i18n)`London, England, W1W 7LT`,
      ],
    },
    {
      title: t(i18n)`Contact us by phone`,
      items: [
        <a href="tel:+441514570319">+441514570319</a>,
        t(i18n)`Mon-Fri 9-4pm (UTC -5)`,
      ],
    },
    {
      title: t(i18n)`Contact us by email`,
      items: [
        <a href="mailto:support@pokerpowerhouse.uk">
          support@pokerpowerhouse.uk
        </a>,
        t(i18n)`Open 24/7`,
      ],
    },
    {
      title: t(i18n)`Politics`,
      items: [
        <Link to={PATHS.cookie}>
          <Trans>Cookie</Trans>
        </Link>,
        <Link to={PATHS.privacyPolicy}>
          <Trans>Privacy Policy</Trans>
        </Link>,
        <Link to={PATHS.termsAndConditions}>
          <Trans>Terms and conditions</Trans>
        </Link>,
        <Link to={PATHS.refundPolicy}>
          <Trans>Refund Policy</Trans>
        </Link>,
        <Link to={PATHS.subscriptionPolicy}>
          <Trans>Subscription Policy</Trans>
        </Link>,
      ],
    },
  ];

  return { footerData };
}
