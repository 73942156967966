import { useContext } from "react";

// context
import { cartContext } from "../../../context/cart-provider/CartProvider";
import { currencyContext } from "../../../context/currency-provider/CurrencyProvider";

// images
import default_background_image_src from "../../../assets/images/default_video_background.jpg";

// icons
import { CloseIcon } from "../../../assets/icons/CloseIcon";

// types
import type { VideoType } from "../../../context/course-provider/CourseProvider.types";

// styles
import styles from "./CartModalVideo.module.scss";

//translation
import { Trans } from "@lingui/macro";

type CartModalVideoProps = {
  cartProduct: VideoType;
};

export function CartModalVideo({ cartProduct }: CartModalVideoProps) {
  const { deleteVideoFromCart } = useContext(cartContext);
  const { getCurrencySymbol } = useContext(currencyContext);

  return (
    <div className={styles.container}>
      <div>
        <img
          src={
            cartProduct.picture
              ? cartProduct.picture
              : default_background_image_src
          }
          alt={cartProduct.title}
        />
      </div>
      <div className={styles.infoWrapper}>
        <p>
          <Trans>Video</Trans> :<span>{cartProduct.title}</span>
        </p>
      </div>

      <div className={styles.priceAndQuantityWrapper}>
        <div className={styles.pricesWrapper}>
          {cartProduct.discountPrice ? (
            <p>
              {getCurrencySymbol()} {cartProduct.discountPrice}
            </p>
          ) : null}
          <div className={cartProduct.discountPrice ? styles.diagonalLine : ""}>
            <p className={cartProduct.discountPrice ? styles.oldPrice : ""}>
              {getCurrencySymbol()} {cartProduct.price}
            </p>
          </div>
        </div>
        <div
          className={styles.removeWrapper}
          onClick={() => deleteVideoFromCart(cartProduct.id)}>
          <CloseIcon color="#A21916" /> <Trans>Remove</Trans>
        </div>
      </div>
    </div>
  );
}
