import React from "react";
import { Outlet } from "react-router-dom";

// components
import { Header } from "../../components/header/Header";
import { Footer } from "../../components/footer/Footer";
import { LoginModal } from "../../side/general/main-screen/login-modal/LoginModal";

// hooks
import { useAuth } from "../../context/user-provider/use-auth";

// styles
import styles from "./GuestLayout.module.scss";

export function GuestLayout(): JSX.Element {
  const { isTypeLoginModal, onSubmit, openLoginModal, closeLoginModal } =
    useAuth();

  return (
    <div className={styles.mainSection}>
      <Header openLoginModal={openLoginModal} />

      <div className={styles.mainContent}>
        <Outlet />
      </div>

      <LoginModal
        isOpen={isTypeLoginModal}
        openLoginModal={openLoginModal}
        onClose={closeLoginModal}
        onSubmit={onSubmit}
      />

      <Footer />
    </div>
  );
}
