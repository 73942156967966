import { useNavigate } from "react-router-dom";

// hooks
import { useBlogAdminFetch } from "../../admin/blog-admin-screen/use-blog-admin";

// consts
import { PATHS } from "../../../route/route.controls";

// components
import { LaterNews } from "./later-news/LaterNews";
import Spinner from "../../../components/spinner/Spinner";
import Button from "../../../components/button/Button";

// styles
import styles from "./Blog.screen.module.scss";

//translation
import { Trans } from "@lingui/macro";

export function BlogScreen() {
  const { isBlogLoading, articles, filteredArticles } = useBlogAdminFetch();

  const navigate = useNavigate();

  return (
    <section className={styles.blogSection}>
      {isBlogLoading ? (
        <div className={styles.blogLoader}>
          <Spinner size="large" />
        </div>
      ) : (
        <>
          {articles.length ? (
            <LaterNews laterNewsData={filteredArticles} />
          ) : (
            <div className={styles.noOneArticlesBlock}>
              <h4>
                <Trans>
                  Unfortunately, there are no articles available at the moment.
                </Trans>
              </h4>

              <Button width="auto" onClick={() => navigate(PATHS.index)}>
                <Trans>Go to main</Trans>
              </Button>
            </div>
          )}
        </>
      )}
    </section>
  );
}
