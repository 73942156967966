import React, { useContext } from "react";

// context
import { cartContext } from "../../../context/cart-provider/CartProvider";

// helpers
import { getMembershipTitle } from "../../../helpers/get-membership-title";

// icons
import { CloseIcon } from "../../../assets/icons/CloseIcon";

// types
import type { MembershipTypesType } from "../../../context/membership-provider/MembershipProvider.types";

// styles
import styles from "./CartModalMembership.module.scss";
import { currencyContext } from "../../../context/currency-provider/CurrencyProvider";

//translation
import { Trans } from "@lingui/macro";

type CartModalMembershipProps = {
  cartProduct: MembershipTypesType;
};

export function CartModalMembership({ cartProduct }: CartModalMembershipProps) {
  const { deleteMembershipFromCart } = useContext(cartContext);
  const { getCurrencySymbol } = useContext(currencyContext);

  return (
    <div className={styles.container}>
      <div className={styles.infoWrapper}>
        <p>
          <Trans>Membership:</Trans>{" "}
          <span>{getMembershipTitle(cartProduct.name)}</span>
        </p>
      </div>
      <div className={styles.priceAndQuantityWrapper}>
        <div className={styles.pricesWrapper}>
          {cartProduct.discountPrice ? (
            <p>
              {getCurrencySymbol()} {cartProduct.discountPrice}
            </p>
          ) : null}
          <div className={cartProduct.discountPrice ? styles.diagonalLine : ""}>
            <p className={cartProduct.discountPrice ? styles.oldPrice : ""}>
              {getCurrencySymbol()} {cartProduct.price}
            </p>
          </div>
        </div>
        <div
          className={styles.removeWrapper}
          onClick={() => deleteMembershipFromCart(cartProduct.id)}>
          <CloseIcon color="#A21916" width={13} height={13} />{" "}
          <Trans>Remove</Trans>
        </div>
      </div>
    </div>
  );
}
