import React, { useContext, useEffect, useState } from "react";

// context
import { courseContext } from "../../../context/course-provider/CourseProvider";

// types
import type { CourseType } from "../../../context/course-provider/CourseProvider.types";

export function useCoursesMenuFetch() {
  const { coursesData, isCoursesLoading } = useContext(courseContext);

  const [beginnerCoursesData, setBeginnerCoursesData] = useState<CourseType[]>(
    []
  );
  const [proCoursesData, setProCoursesData] = useState<CourseType[]>([]);
  const [vipCoursesData, setVipCoursesData] = useState<CourseType[]>([]);

  useEffect(() => {
    const beginnerCourses = coursesData?.filter(
      (course) => course.category === "beginner"
    );

    if (beginnerCourses) {
      setBeginnerCoursesData(beginnerCourses);
    }

    const proCourses = coursesData?.filter(
      (course) => course.category === "pro"
    );

    if (proCourses) {
      setProCoursesData(proCourses);
    }

    const vipCourses = coursesData?.filter(
      (course) => course.category === "vip"
    );

    if (vipCourses) {
      setVipCoursesData(vipCourses);
    }
  }, [coursesData]);

  return {
    isCoursesLoading,
    beginnerCoursesData,
    proCoursesData,
    vipCoursesData,
  };
}
