export const API_URL_COURSE = "/course";
export const API_URL_MY_COURSES = "/course/my";
export const API_URL_VIDEO = "/video";

export const INIT_COURSE_FORM_DATA = {
  title: "",
  description: "",
  price: "",
  uri: "",
  picture: "",
  isPublished: false,
};
