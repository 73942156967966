import React from "react";

// icons
import { StarIcon } from "../../../../assets/icons/StarIcon";

// styles
import styles from "./CustomReviewItem.module.scss";

//translation
import { Trans } from "@lingui/macro";

export default function CustomReviewItem() {
  const stars = Array.from({ length: 5 }, (_, index) => {
    if (index < 5) {
      return <StarIcon key={index} />;
    } else {
      return <StarIcon color="#f4f4f4" key={index} />;
    }
  });
  return (
    <div className={styles.container}>
      <div>
        <h5 className={styles.reviewText}>
          <Trans>
            I've taken other poker courses before, but this one stood out for
            its emphasis on logical thinking.
          </Trans>
        </h5>
      </div>

      <div>
        <div className={styles.name}>David</div>

        <div className={styles.rating}>
          <div>{stars}</div>
          <div className={styles.separator}>|</div>
          <div className={styles.date}>
            <Trans>June 15, 2024</Trans>
          </div>
        </div>
      </div>
    </div>
  );
}
