import { RefObject, useEffect, useRef } from "react";

// ------- TYPES -------
type OptionsType = {
  elementRef?: RefObject<HTMLElement>;
};

// ------- CONSTANTS -------
const isBrowser = typeof window !== "undefined";
const isIosDevice =
  isBrowser &&
  window.navigator &&
  window.navigator.platform &&
  /iP(ad|hone|od)/.test(window.navigator.platform);
const bodies: Map<
  HTMLElement,
  {
    counter: number;
    initialOverflow: CSSStyleDeclaration["overflow"];
  }
> = new Map();
const doc: Document | undefined =
  typeof document === "object" ? document : undefined;

// ------- HOOK HELPER METHODS -------
// method to attach event listener to the passed element
const on = <T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T["addEventListener"]> | [string, Function | null, ...any]
): void => {
  if (obj && obj.addEventListener) {
    obj.addEventListener(
      ...(args as Parameters<HTMLElement["addEventListener"]>)
    );
  }
};

// method to remove event listener from the passed element
const off = <T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args:
    | Parameters<T["removeEventListener"]>
    | [string, Function | null, ...any]
): void => {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(
      ...(args as Parameters<HTMLElement["removeEventListener"]>)
    );
  }
};

// method to check whether we have data-allow-scroll attribute, or whether it's pure-modal component, to allow touch scroll
const checkForAllowTouchScrollAttr = (element: HTMLElement | null): any => {
  if (!element) return false;

  const hasDataAllowScroll = element?.getAttribute?.("data-allow-scroll");

  // Need this check to avoid blocking scroll on IOS for Modal component
  const isModalComponent = element?.classList?.contains?.("scrollable");

  if (hasDataAllowScroll || isModalComponent) return true;
  return checkForAllowTouchScrollAttr(element?.parentNode as HTMLElement);
};

// method to prevent default behavior of the touch event
const preventDefaultTouchEventAction = (rawEvent: TouchEvent): boolean => {
  const e = rawEvent || window.event;

  // Do not prevent element has data-allow-scroll attribute, and it's present on parent element of the touch target element or it's pure-modal with scrollable className
  if (checkForAllowTouchScrollAttr(e.target as HTMLElement)) return true;

  // Do not prevent if the event has more than one touch (usually meaning this is a multi touch gesture like pinch to zoom)
  if (e.touches.length > 1) return true;

  // otherwise prevent default action and do not register touch move
  e.preventDefault?.();
  return false;
};

// method to get closest body tag element to tha passed element on the page
const getClosestBody = (
  el: Element | HTMLElement | HTMLIFrameElement | null
): HTMLElement | null => {
  if (!el) {
    return null;
  } else if (el.tagName === "BODY") {
    return el as HTMLElement;
  } else if (el.tagName === "IFRAME") {
    const document = (el as HTMLIFrameElement).contentDocument;
    return document ? document.body : null;
  } else if (!(el as HTMLElement).offsetParent) {
    return null;
  }

  return getClosestBody((el as HTMLElement).offsetParent!);
};

let documentListenerAdded = false;

// ------- HOOKS -------
const useLockBodyScrollMock = (
  locked: boolean = true,
  options?: OptionsType
) => {}; // Mock for non browser environment
/**
 * Custom hook to disable body scroll on all kinds of devices
 *
 * @param locked – boolean value whether we should lock scroll or not
 * @param options – objejt with options for hook:
 *    @param options.elementRef – [OPTIONAL] ref to the element on which we want to lock scroll
 *
 * --- NOTES ---
 *    if element has scroll inside, on ios it will not scroll, cuz we need to prevent touch events, to disable body scroll, so in this case you need to set on the parent element, that has scroll inside data-allow-scroll attribute,
 * so on IOS, when user tries to scroll, it will check whether we should allow scroll on IOS or not, for pure-modal with scrollable className it will allow scroll as well
 */
const useLockBodyScroll = (locked: boolean = true, options?: OptionsType) => {
  const bodyRef = useRef(doc!.body);
  const elementRef = options?.elementRef || bodyRef;

  // Lock scroll on body
  const lock = (body: any) => {
    const bodyInfo = bodies.get(body);
    if (!bodyInfo) {
      bodies.set(body, { counter: 1, initialOverflow: body.style.overflow });
      if (isIosDevice) {
        if (!documentListenerAdded) {
          on(document, "touchmove", preventDefaultTouchEventAction, {
            passive: false,
          });

          documentListenerAdded = true;
        }
      } else {
        body.style.overflow = "hidden";
      }
    } else {
      bodies.set(body, {
        counter: bodyInfo.counter + 1,
        initialOverflow: bodyInfo.initialOverflow,
      });
    }
  };

  // unlock scroll on body
  const unlock = (body: any) => {
    const bodyInfo = bodies.get(body);
    if (bodyInfo) {
      if (bodyInfo.counter === 1) {
        bodies.delete(body);
        if (isIosDevice) {
          body.ontouchmove = null;

          if (documentListenerAdded) {
            off(document, "touchmove", preventDefaultTouchEventAction);
            documentListenerAdded = false;
          }
        } else {
          body.style.overflow = bodyInfo.initialOverflow;
        }
      } else {
        bodies.set(body, {
          counter: bodyInfo.counter - 1,
          initialOverflow: bodyInfo.initialOverflow,
        });
      }
    }
  };

  useEffect(() => {
    const body = getClosestBody(elementRef!.current);

    if (!body) return;

    if (locked) lock(body);
    else unlock(body);
  }, [locked, elementRef.current]);

  // clean up, on un-mount
  useEffect(() => {
    const body = getClosestBody(elementRef!.current);
    if (!body) return;

    return () => unlock(body);
  }, []);
};

export default !doc ? useLockBodyScrollMock : useLockBodyScroll;
