import React, { SVGProps } from "react";

const CloseEyeIcon = ({
  className,
  color = "#FDFDFD",
  width = 20,
  height = 20,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.200195 9.99997C1.12139 8.19299 2.52424 6.67588 4.25372 5.61631C5.98319 4.55674 7.97195 3.99597 10.0002 3.99597C12.0284 3.99597 14.0172 4.55674 15.7467 5.61631C17.4762 6.67588 18.879 8.19299 19.8002 9.99997C18.879 11.807 17.4762 13.3241 15.7467 14.3836C14.0172 15.4432 12.0284 16.004 10.0002 16.004C7.97195 16.004 5.98319 15.4432 4.25372 14.3836C2.52424 13.3241 1.12139 11.807 0.200195 9.99997ZM10.0002 14C11.0611 14 12.0785 13.5785 12.8286 12.8284C13.5788 12.0783 14.0002 11.0608 14.0002 9.99997C14.0002 8.93911 13.5788 7.92169 12.8286 7.17155C12.0785 6.4214 11.0611 5.99997 10.0002 5.99997C8.93933 5.99997 7.92191 6.4214 7.17177 7.17155C6.42162 7.92169 6.0002 8.93911 6.0002 9.99997C6.0002 11.0608 6.42162 12.0783 7.17177 12.8284C7.92191 13.5785 8.93933 14 10.0002 14ZM10.0002 12C9.46976 12 8.96105 11.7893 8.58598 11.4142C8.21091 11.0391 8.0002 10.5304 8.0002 9.99997C8.0002 9.46954 8.21091 8.96083 8.58598 8.58576C8.96105 8.21069 9.46976 7.99997 10.0002 7.99997C10.5306 7.99997 11.0393 8.21069 11.4144 8.58576C11.7895 8.96083 12.0002 9.46954 12.0002 9.99997C12.0002 10.5304 11.7895 11.0391 11.4144 11.4142C11.0393 11.7893 10.5306 12 10.0002 12Z"
        fill={color}
      />
      <rect
        x="1.91895"
        y="0.504333"
        width="24.3529"
        height="2"
        rx="1"
        transform="rotate(45 1.91895 0.504333)"
        fill={color}
      />
    </svg>
  );
};
export { CloseEyeIcon };
