import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// hooks
import { useMyCoursesFetch } from "../../client/my-courses-user-screen/use-my-courses";
import { useWindowSize } from "../../../hooks/use-window-size/use-window-size";

// consts
import { PATHS } from "../../../route/route.controls";

// components
import Spinner from "../../../components/spinner/Spinner";
import { CourseMenuItem } from "../../../components/header/course-menu-item/CourseMenuItem";

// styles
import styles from "./MobileCourse.screen.module.scss";

//translation
import { Trans } from "@lingui/macro";

export function MobileCourseScreen() {
  const {
    isMyCoursesLoading,
    beginnerCoursesData,
    proCoursesData,
    vipCoursesData,
  } = useMyCoursesFetch("mobileCourses");

  const navigate = useNavigate();

  const { width } = useWindowSize();

  useEffect(() => {
    if (width >= 1024) {
      navigate(PATHS.index);
    }
  }, [width]);

  return (
    <div>
      {isMyCoursesLoading ? (
        <div className={styles.myCourseLoader}>
          <Spinner size="large" />
        </div>
      ) : (
        <div className={styles.emptyCoursesWrapper}>
          {beginnerCoursesData.length ? (
            <div className={styles.coursesWrapper}>
              <div className={styles.courseTitleWrapper}>
                <p className={styles.courseTitle}>
                  <Trans>Beginner</Trans>
                </p>
                <p className={styles.courseTitle}>
                  <Trans>courses</Trans>
                </p>
              </div>

              <div className={styles.separator} />

              <div className={styles.coursesList}>
                {beginnerCoursesData.map((course) => (
                  <CourseMenuItem key={course.id} course={course} />
                ))}
              </div>
            </div>
          ) : (
            <div className={styles.emptyCoursesTitleWrapper}>
              <h5>
                <Trans>
                  Unfortunately, there are currently no courses available for
                  this category
                </Trans>
              </h5>
            </div>
          )}

          {proCoursesData.length ? (
            <div className={styles.coursesWrapper}>
              <div className={styles.courseTitleWrapper}>
                <p className={styles.courseTitle}>
                  <Trans>Pro</Trans>
                </p>
                <p className={styles.courseTitle}>
                  <Trans>courses</Trans>
                </p>
              </div>

              <div className={styles.separator} />

              <div className={styles.coursesList}>
                {proCoursesData.map((course) => (
                  <CourseMenuItem key={course.id} course={course} />
                ))}
              </div>
            </div>
          ) : (
            <div className={styles.emptyCoursesTitleWrapper}>
              <h5>
                <Trans>
                  Unfortunately, there are currently no courses available for
                  this category
                </Trans>
              </h5>
            </div>
          )}

          {vipCoursesData.length ? (
            <div className={styles.coursesWrapper}>
              <div className={styles.courseTitleWrapper}>
                <p className={styles.courseTitle}>
                  <Trans>VIP</Trans>
                </p>
                <p className={styles.courseTitle}>
                  <Trans>courses</Trans>
                </p>
              </div>

              <div className={styles.separator} />

              <div className={styles.coursesList}>
                {vipCoursesData.map((course) => (
                  <CourseMenuItem key={course.id} course={course} />
                ))}
              </div>
            </div>
          ) : (
            <div className={styles.emptyCoursesTitleWrapper}>
              <h5>
                <Trans>
                  Unfortunately, there are currently no courses available for
                  this category
                </Trans>
              </h5>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
