import React from "react";
import { useNavigate } from "react-router-dom";

// consts
import { PATHS } from "../../../route/route.controls";

// components
import Button from "../../../components/button/Button";

// styles
import styles from "./PageNotFound.screen.module.scss";

//translation
import { Trans } from "@lingui/macro";

export function PageNotFound() {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.mainWrapper}>
        <div className={styles.info}>
          <h2>404</h2>
          <div className={styles.seperator} />
          <p>
            <Trans>This page could not be found</Trans>
          </p>
        </div>

        <Button
          onClick={() => navigate(PATHS.index)}
          width="auto"
          className={styles.button}>
          <Trans>Back to main</Trans>
        </Button>
      </div>
    </div>
  );
}
