import React from "react";
import { useNavigate } from "react-router-dom";

// images
import logo_src from "../../assets/images/logo.png";
import visa_image from "../../assets/images/visa_image.png";

// consts
import { PATHS } from "../../route/route.controls";

//hooks
import { useFooterConsts } from "./use-footer-consts";

// styles
import styles from "./Footer.module.scss";

export function Footer() {
  const { footerData } = useFooterConsts();

  const navigate = useNavigate();

  return (
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.cardsWrapper}>
          <picture onClick={() => navigate(PATHS.index)}>
            <source
              media="(min-width: 768px)"
              srcSet={logo_src}
              className={styles.logoImnage}
            />

            <img
              src={logo_src}
              alt="power-house-logo"
              className={styles.logo}
            />
          </picture>
          <img
            src={visa_image}
            alt="visa cards"
            className={styles.cardsImage}
          />
        </div>

        <div className={styles.footerItemWrapper}>
          {footerData.map((footerItem) => (
            <div key={footerItem.title} className={styles.titleWrapper}>
              <p className={styles.title}>{footerItem.title}</p>

              <div className={styles.footerItems}>
                {footerItem.items.map((item, index) => (
                  <p className={styles.footerItem} key={index}>
                    {item}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </footer>
  );
}
