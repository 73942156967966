import React, { useState } from "react";
import classnames from "classnames";

// hooks
import { useCoursesMenuFetch } from "./use-courses-menu";

// components
import Spinner from "../../spinner/Spinner";
import { CourseMenuItem } from "../course-menu-item/CourseMenuItem";

// styles
import styles from "./CoursesMenu.module.scss";

//translation
import { Trans } from "@lingui/macro";

export function CoursesMenu() {
  const {
    isCoursesLoading,
    beginnerCoursesData,
    proCoursesData,
    vipCoursesData,
  } = useCoursesMenuFetch();

  const [selectedCategory, setSelectedCategory] = useState("Beginner");

  const coursesData = () => {
    switch (selectedCategory) {
      case "Beginner":
        return beginnerCoursesData;
      case "PRO":
        return proCoursesData;
      default:
        return vipCoursesData;
    }
  };

  return (
    <div className={styles.menu} onClick={(e) => e.stopPropagation()}>
      <div className={styles.categoriesList}>
        <div
          onClick={() => setSelectedCategory("Beginner")}
          className={classnames(
            styles.category,
            selectedCategory === "Beginner" ? styles.activeCategory : "",
          )}>
          <Trans>Beginner</Trans>
        </div>
        <div
          onClick={() => setSelectedCategory("PRO")}
          className={classnames(
            styles.category,
            selectedCategory === "PRO" ? styles.activeCategory : "",
          )}>
          <Trans>PRO</Trans>
        </div>
        <div
          onClick={() => setSelectedCategory("VIP")}
          className={classnames(
            styles.category,
            selectedCategory === "VIP" ? styles.activeCategory : "",
          )}>
          <Trans>VIP</Trans>
        </div>
      </div>
      {isCoursesLoading ? (
        <div className={styles.coursesLoader}>
          <Spinner />
        </div>
      ) : (
        <div className={styles.coursesList}>
          {coursesData().length ? (
            coursesData().map((course) => (
              <CourseMenuItem key={course.id} course={course} />
            ))
          ) : (
            <h5>
              <Trans>
                Unfortunately, there are currently no courses available for the
                selected category
              </Trans>
            </h5>
          )}
        </div>
      )}
    </div>
  );
}
