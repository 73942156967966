// context
import { useContext } from "react";
import { localesContext } from "../../../context/local-provider/LocalProvider";

//translation
import { t } from "@lingui/macro";

export function useCoursesAdminScreenConsts() {
  const { i18n } = useContext(localesContext);
  const INIT_COURSE_ADMIN_FORM_DATA = {
    title: "",
    price: "",
    discountPrice: "",
    picture: "",
    willLearn: [],
    willGet: [],
    isPublished: false,
    category: "",
    ids: [],
  };

  const INIT_VIDEO_ADMIN_FORM_DATA = {
    title: "",
    description: "",
    courseId: "",
    picture: "",
    source: {
      createdAt: 0,
      fileName: "",
      fileSize: 0,
      key: "",
      extension: "",
    },
    subtitles: [],
    isFree: false,
    price: null,
    discountPrice: null,
  };

  const INIT_VIDEO_ADMIN_DATA = {
    id: "",
    title: "",
    description: "",
    picture: "",
    source: {
      createdAt: 0,
      fileName: "",
      fileSize: 0,
      key: "",
      extension: "",
    },
  };

  const INIT_COURSE_ADMIN_DATA = {
    id: "",
    title: t(i18n)`New course`,
    price: null,
    discountPrice: null,
    picture: "",
    isPublished: false,
    category: "",
    videos: [],
    createdAt: "",
    updatedAt: "",
  };

  return {
    INIT_COURSE_ADMIN_FORM_DATA,
    INIT_VIDEO_ADMIN_FORM_DATA,
    INIT_VIDEO_ADMIN_DATA,
    INIT_COURSE_ADMIN_DATA,
  };
}
