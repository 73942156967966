// layouts
import { AdminLayout } from "../layout/admin-layout/AdminLayout";
import { GuestLayout } from "../layout/guest-layout/GuestLayout";
import { UserLayout } from "../layout/UserLayout/UserLayout";
import { CoursesAdminScreen } from "../side/admin/courses-admin-screen/CoursesAdminScreen";
import { ProfileAdminScreen } from "../side/client/profile-user-screen/ProfileUserScreen";
import { MembershipAdminScreen } from "../side/admin/membership-admin-screen/MemberShipAdminScreen";
import { BlogAdminScreen } from "../side/admin/blog-admin-screen/BlogAdminScreen";
import { UsersAdminScreen } from "../side/admin/users-admin-screen/UsersAdminScreen";
import { MainScreen } from "../side/general/main-screen/Main.screen";
import { MembershipScreen } from "../side/general/membership-screen/Membership.screen";
import { MyCoursesUserScreen } from "../side/client/my-courses-user-screen/MyCoursesUserScreen";
import { PageNotFound } from "../side/general/not-found/PageNotFound.screen";
import { ResetPasswordScreen } from "../side/general/reset-password-screen/ResetPasswordScreen";
import { CourseOverview } from "../side/general/courses-overview-screen/CoursesOverview.screen";
import { CourseScreen } from "../side/general/course-screen/Course.screen";
import { BlogScreen } from "../side/general/blog-screen/Blog.screen";
import { AboutScreen } from "../side/general/about-screen/About.screen";
import { PaymentStatus } from "../side/general/payment-failed/PaymentStatus";
import { CookieScreen } from "../side/general/cookie-screen/CookieScreen";
import { PrivacyPolicyScreen } from "../side/general/privacy-policy-screen/PrivacyPolicyScreen";
import { TermsAndConditionsScreen } from "../side/general/terms-and-conditions-screen/TermsAndConditionsScreen";
import { CurrentBlogScreen } from "../side/general/current-blog-screen/CurrentBlog.screen";

// consts
import { PATHS } from "./route.controls";
import { MobileCourseScreen } from "../side/general/mobile-course-screen/MobileCourse.screen";
import { RefundPolicyScreen } from "../side/general/refund-policy-screen/RefundPolicyScreen";
import { SubscriptionScreen } from "../side/general/subscription-screen/SubscriptionScreen";

export const guestRoutes = [
  {
    element: <GuestLayout />,
    children: [
      {
        path: PATHS.index,
        element: <MainScreen />,
        name: "MainScreen",
      },
      {
        path: PATHS.membership,
        element: <MembershipScreen />,
        name: "MembershipScreen",
      },
      {
        path: PATHS.courseOverview,
        element: <CourseOverview />,
        name: "CourseOverview",
      },
      {
        path: PATHS.mobileCourses,
        element: <MobileCourseScreen />,
        name: "MobileCourseScreen",
      },
      {
        path: PATHS.course,
        element: <CourseScreen />,
        name: "CourseScreen",
      },
      {
        path: PATHS.blog,
        element: <BlogScreen />,
        name: "BlogScreen",
      },
      {
        path: PATHS.aboutUs,
        element: <AboutScreen />,
        name: "AboutScreen",
      },
      {
        path: PATHS.currentBlog,
        element: <CurrentBlogScreen />,
        name: "CurrentBlogScreen",
      },
      {
        path: PATHS.reset,
        element: <ResetPasswordScreen />,
        name: "ResetPasswordScreen",
      },
      {
        path: PATHS.cookie,
        element: <CookieScreen />,
        name: "CookieScreen",
      },
      {
        path: PATHS.privacyPolicy,
        element: <PrivacyPolicyScreen />,
        name: "PrivacyPolicyScreen",
      },
      {
        path: PATHS.termsAndConditions,
        element: <TermsAndConditionsScreen />,
        name: "TermsAndConditionsScreen",
      },
      {
        path: PATHS.refundPolicy,
        element: <RefundPolicyScreen />,
        name: "RefundPolicyScreen",
      },
      {
        path: PATHS.subscriptionPolicy,
        element: <SubscriptionScreen />,
        name: "SubscriptionScreen",
      },
    ],
  },
  {
    path: "*",
    name: "PageNotFound",
    element: <PageNotFound />,
  },
];

export const userRoutes = [
  {
    element: <GuestLayout />,
    children: [
      {
        path: PATHS.index,
        element: <MainScreen />,
        name: "MainScreen",
      },
      {
        path: PATHS.membership,
        element: <MembershipScreen />,
        name: "MembershipScreen",
      },
      {
        path: PATHS.courseOverview,
        element: <CourseOverview />,
        name: "CourseOverview",
      },
      {
        path: PATHS.mobileCourses,
        element: <MobileCourseScreen />,
        name: "MobileCourseScreen",
      },
      {
        path: PATHS.course,
        element: <CourseScreen />,
        name: "CourseScreen",
      },
      {
        path: PATHS.blog,
        element: <BlogScreen />,
        name: "BlogScreen",
      },
      {
        path: PATHS.aboutUs,
        element: <AboutScreen />,
        name: "AboutScreen",
      },
      {
        path: PATHS.currentBlog,
        element: <CurrentBlogScreen />,
        name: "CurrentBlogScreen",
      },
      {
        path: PATHS.paymentFailed,
        element: <PaymentStatus />,
        name: "PaymentFailed",
      },
      {
        path: PATHS.paymentSuccess,
        element: <PaymentStatus />,
        name: "paymentSuccess",
      },
      {
        path: PATHS.cookie,
        element: <CookieScreen />,
        name: "CookieScreen",
      },
      {
        path: PATHS.privacyPolicy,
        element: <PrivacyPolicyScreen />,
        name: "PrivacyPolicyScreen",
      },
      {
        path: PATHS.termsAndConditions,
        element: <TermsAndConditionsScreen />,
        name: "TermsAndConditionsScreen",
      },
      {
        path: PATHS.refundPolicy,
        element: <RefundPolicyScreen />,
        name: "RefundPolicyScreen",
      },
    ],
  },
  {
    element: <UserLayout />,
    children: [
      {
        path: PATHS.profile,
        element: <ProfileAdminScreen />,
        name: "ProfileAdminScreen",
      },
      {
        path: PATHS.myCourses,
        element: <MyCoursesUserScreen />,
        name: "MyCoursesUserScreen",
      },
    ],
  },
  {
    path: "*",
    name: "PageNotFound",
    element: <PageNotFound />,
  },
];

export const adminRoutes = [
  {
    element: <GuestLayout />,
    children: [
      {
        path: PATHS.index,
        element: <MainScreen />,
        name: "MainScreen",
      },
      {
        path: PATHS.membership,
        element: <MembershipScreen />,
        name: "MembershipScreen",
      },
      {
        path: PATHS.courseOverview,
        element: <CourseOverview />,
        name: "CourseOverview",
      },
      {
        path: PATHS.mobileCourses,
        element: <MobileCourseScreen />,
        name: "MobileCourseScreen",
      },
      {
        path: PATHS.course,
        element: <CourseScreen />,
        name: "CourseScreen",
      },
      {
        path: PATHS.blog,
        element: <BlogScreen />,
        name: "BlogScreen",
      },
      {
        path: PATHS.aboutUs,
        element: <AboutScreen />,
        name: "AboutScreen",
      },
      {
        path: PATHS.currentBlog,
        element: <CurrentBlogScreen />,
        name: "CurrentBlogScreen",
      },
      {
        path: PATHS.paymentFailed,
        element: <PaymentStatus />,
        name: "PaymentFailed",
      },
      {
        path: PATHS.paymentSuccess,
        element: <PaymentStatus />,
        name: "paymentSuccess",
      },
      {
        path: PATHS.cookie,
        element: <CookieScreen />,
        name: "CookieScreen",
      },
      {
        path: PATHS.privacyPolicy,
        element: <PrivacyPolicyScreen />,
        name: "PrivacyPolicyScreen",
      },
      {
        path: PATHS.termsAndConditions,
        element: <TermsAndConditionsScreen />,
        name: "TermsAndConditionsScreen",
      },
      {
        path: PATHS.refundPolicy,
        element: <RefundPolicyScreen />,
        name: "RefundPolicyScreen",
      },
      {
        path: PATHS.subscriptionPolicy,
        element: <SubscriptionScreen />,
        name: "SubscriptionScreen",
      },
    ],
  },
  {
    element: <UserLayout />,
    children: [
      {
        path: PATHS.profile,
        element: <ProfileAdminScreen />,
        name: "ProfileAdminScreen",
        isAccess: true,
      },
      {
        path: PATHS.myCourses,
        element: <MyCoursesUserScreen />,
        name: "MyCoursesUserScreen",
      },
    ],
  },
  {
    element: <AdminLayout />,
    children: [
      {
        path: PATHS.adminMemberships,
        element: <MembershipAdminScreen />,
        name: "MembershipAdminScreen",
      },
      {
        path: PATHS.adminBlog,
        element: <BlogAdminScreen />,
        name: "BlogAdminScreen",
      },
      {
        path: PATHS.adminUsers,
        element: <UsersAdminScreen />,
        name: "UsersAdminScreen",
      },
      {
        path: PATHS.adminCourses,
        element: <CoursesAdminScreen />,
        name: "CoursesAdminScreen",
      },
    ],
  },
  {
    path: "*",
    name: "PageNotFound",
    element: <PageNotFound />,
  },
];
