import React, { FunctionComponent, useContext, useMemo, useState } from "react";

// context
import { apiContext } from "../api-provider/ApiProvider";

// consts
import {
  API_URL_MEMBERSHIP,
  API_URL_MEMBERSHIP_TYPES,
} from "./MembershipProvider.consts";

// schemas
import {
  allMembershipsSchema,
  allMembershipsTypesSchema,
  membershipSchema,
  membershipTypesSchema,
} from "./MembershipProvider.schemas";

// types
import type {
  AddMembershipFormType,
  MembershipContext,
  MembershipProviderProps,
  MembershipType,
  MembershipTypesType,
} from "./MembershipProvider.types";
import { currencyContext } from "../currency-provider/CurrencyProvider";

export const membershipContext = React.createContext({} as MembershipContext);

export const MembershipProvider: FunctionComponent<MembershipProviderProps> = (
  props
) => {
  const { api } = useContext(apiContext);
  const { getCurrency } = useContext(currencyContext);

  const { children } = props;

  const [membershipsForCurrentUserData, setMembershipsForCurrentUserData] =
    useState<MembershipType[] | null>(null);
  const [membershipsTypesData, setMembershipsTypesData] = useState<
    MembershipTypesType[] | null
  >(null);
  const [adminMembershipsTypesData, setAdminMembershipsTypesData] = useState<
    MembershipTypesType[] | null
  >(null);
  const [membershipTypeById, setMembershipTypeById] =
    useState<MembershipTypesType | null>(null);

  const getMembershipsForCurrentUser = async () => {
    try {
      const currentCurrency = getCurrency();

      const response = await api(
        `${API_URL_MEMBERSHIP}?currency=${currentCurrency}`,
        {},
        allMembershipsSchema
      );

      if (response) {
        const allMembershipsForCurrentUser = response.data;

        setMembershipsForCurrentUserData(allMembershipsForCurrentUser);
        return allMembershipsForCurrentUser;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const getMembershipsTypes = async (isAdmin?: boolean) => {
    try {
      const currentCurrency = getCurrency();

      const response = await api(
        `${API_URL_MEMBERSHIP_TYPES}?currency=${
          isAdmin ? "EUR" : currentCurrency
        }`,
        {},
        allMembershipsTypesSchema
      );

      if (response) {
        const allMembershipsTypes = response.data;

        // FOR ADMIN
        if (isAdmin) {
          setAdminMembershipsTypesData(allMembershipsTypes);
          return allMembershipsTypes;
        }

        // FOR USERS AND GUESTS
        setMembershipsTypesData(allMembershipsTypes);
        return allMembershipsTypes;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const getMembershipTypeById = async (membershipTypeById: string) => {
    try {
      const currentCurrency = getCurrency();

      const response = await api(
        `${API_URL_MEMBERSHIP_TYPES}/${membershipTypeById}?currency=${currentCurrency}`,
        {},
        membershipTypesSchema
      );

      if (response) {
        const currentMembershipType = response;

        setMembershipTypeById(currentMembershipType);
        return currentMembershipType;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const addMembershipType = async (formData: AddMembershipFormType) => {
    try {
      await api(
        API_URL_MEMBERSHIP_TYPES,
        {
          method: "POST",
          data: formData,
        },
        membershipTypesSchema
      );
    } catch (error) {
      throw error;
    }
  };

  const updateMembershipType = async (
    membershipTypeId: string,
    formData: AddMembershipFormType
  ) => {
    try {
      await api(`${API_URL_MEMBERSHIP_TYPES}/${membershipTypeId}`, {
        method: "PUT",
        data: formData,
      });

      setMembershipsTypesData((prev) => {
        if (!prev) return null;
        const res = prev.map((membership) => {
          if (membership.id === membershipTypeId) {
            return {
              ...membership,
              options: formData.options,
              price: String(formData.price),
              discountPrice: formData.discountPrice
                ? String(formData.discountPrice)
                : null,
            };
          } else {
            return membership;
          }
        });

        return res;
      });
    } catch (error) {
      throw error;
    }
  };

  const contextValue = useMemo(
    () => ({
      getMembershipsForCurrentUser,
      getMembershipsTypes,
      getMembershipTypeById,
      addMembershipType,
      updateMembershipType,
      membershipsForCurrentUserData,
      membershipsTypesData,
      adminMembershipsTypesData,
      membershipTypeById,
    }),
    [
      getMembershipsForCurrentUser,
      getMembershipsTypes,
      getMembershipTypeById,
      addMembershipType,
      updateMembershipType,
      membershipsForCurrentUserData,
      membershipsTypesData,
      adminMembershipsTypesData,
      membershipTypeById,
    ]
  );

  return (
    <membershipContext.Provider value={contextValue}>
      {children}
    </membershipContext.Provider>
  );
};
