import { z } from "zod";

// schemas
import {
  courseSchema,
  videoSchema,
} from "../course-provider/CourseProvider.schemas";

export const orderSchema = z.object({
  id: z.string(),
  courses: z.array(courseSchema).nullable().optional(),
  videos: z.array(videoSchema).nullable().optional(),
  status: z.enum(["Completed", "Declined", "Error", "Refunded", "Pending"]),
  membershipTypeId: z.string().nullable().optional(),
  total: z.string(),
  courseId: z.string().nullable().optional(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const orderStatusSchema = z.object({
  id: z.string(),
  status: z.string(),
});

export const allOrdersSchema = z.object({
  total: z.number(),
  data: z.array(orderSchema),
});
