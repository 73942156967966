import { z } from "zod";

export const couponSchema = z.object({
  id: z.string(),
  code: z.string().min(3).max(32),
  description: z.string(),
  amount: z.string(),
  isActivated: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const couponFormSchema = couponSchema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
});

export const allCouponsSchema = z.object({
  total: z.number(),
  data: z.array(couponSchema),
});
