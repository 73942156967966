import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";

// consts
import { INIT_BLOG_ADMIN_FORM_DATA } from "./BlogAdminScreen.consts";

// context
import { errorContext } from "../../../context/error/ErrorProvider";
import { blogContext } from "../../../context/blog-provider/BlogProvider";
import { storageContext } from "../../../context/storage-provider/StorageProvider";
import { localesContext } from "../../../context/local-provider/LocalProvider";

// types
import type {
  BlogFormType,
  BlogType,
} from "../../../context/blog-provider/BlogProvider.types";

//translation
import { t } from "@lingui/macro";

export function useBlogAdminForm(
  modalType: "create" | "edit" | null,
  blog: BlogType | null
) {
  const { i18n } = useContext(localesContext);
  const { error, success } = useContext(errorContext);
  const { createBlog, editBlog, deleteBlog } = useContext(blogContext);
  const { uploadImage } = useContext(storageContext);

  const pictureInputRef = useRef<any>(null);

  const [isImageUploading, setIsImageUploading] = useState(false);
  const [blogFormData, setBlogFormData] = useState<BlogFormType>(
    INIT_BLOG_ADMIN_FORM_DATA
  );

  useEffect(() => {
    if (blog && modalType === "edit") {
      const { title, content, picture, author } = blog;

      setBlogFormData({
        title,
        content,
        picture,
        author,
      });
    }
  }, [setBlogFormData, blog, modalType]);

  const handleChangeBlogAdminData = useCallback(
    async (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      try {
        const { name, value } = e.target;

        if (name === "picture") {
          const file = pictureInputRef.current.files[0];

          const allowedTypes = ["image/png", "image/jpeg"];

          if (!allowedTypes.includes(file.type)) {
            return error(
              t(i18n)`The file type is incorrect. Please upload a PNG or JPEG file.`
            );
          }

          if (file.size > 400000) {
            return error(
              t(i18n)`The image size is too large. Please try using a different image under 350 KB`
            );
          }

          setIsImageUploading(true);
          const result = await uploadImage(file, "blog");

          if (result) {
            return setBlogFormData((prev) => ({
              ...prev,
              [name]: result,
            }));
          }
        }

        setBlogFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      } catch (e) {
        error(e);
      } finally {
        setIsImageUploading(false);
      }
    },
    [setBlogFormData, error]
  );

  const handleChangeEditorData = useCallback(
    (text: string) => {
      setBlogFormData((prev) => ({ ...prev, content: text }));
    },
    [setBlogFormData, error]
  );

  const onSubmit = useCallback(
    async (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      try {
        e.preventDefault();

        const { title, content, picture, author } = blogFormData;

        if (blog && modalType === "edit") {
          // edit
          await editBlog(blog.id, {
            title,
            content,
            picture: picture.split("/").pop() || "",
            author,
          });

          success(t(i18n)`The article has been successfully edited.`);
        } else {
          // create

          await createBlog({
            title,
            content,
            picture: picture.split("/").pop() || "",
            author,
          });

          success(t(i18n)`The article has been successfully created.`);
        }
      } catch (e) {
        error(e);
      }
    },
    [blogFormData, error, success]
  );

  const handleRemoveBlog = useCallback(
    async (blogId?: string) => {
      try {
        if (blogId) {
          await deleteBlog(blogId);
          success(t(i18n)`The article has been successfully deleted`);
        }
      } catch (e) {
        throw e;
      }
    },
    [deleteBlog, success]
  );

  const handleCloseModal = () => {
    setBlogFormData(INIT_BLOG_ADMIN_FORM_DATA);
  };

  const isDisabledButton = useMemo(
    () =>
      !blogFormData.title ||
      !blogFormData.content ||
      !blogFormData.picture ||
      !blogFormData.author,
    [blogFormData]
  );

  return {
    blogFormData,
    pictureInputRef,
    isDisabledButton,
    isImageUploading,
    handleChangeBlogAdminData,
    handleChangeEditorData,
    handleRemoveBlog,
    handleCloseModal,
    onSubmit,
  };
}

export function useBlogAdminFetch() {
  const { error } = useContext(errorContext);
  const { getBlogs, getBlogsByNameFilter, filteredbBogsData, blogsData } =
    useContext(blogContext);

  const [isBlogLoading, setIsBlogLoading] = useState(false);

  const [articles, setArticles] = useState<BlogType[]>([]);
  const [filteredArticles, setFilteredArticles] = useState<BlogType[]>([]);

  useEffect(() => {
    if (blogsData) {
      const sortedBlogsData = blogsData.sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );

      setArticles(sortedBlogsData);
    }

    if (filteredbBogsData) {
      const sortedBlogsData = filteredbBogsData.sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );

      setFilteredArticles(sortedBlogsData);
    }
  }, [filteredbBogsData]);

  const blogFetch = async () => {
    try {
      setIsBlogLoading(true);
      await getBlogs();

      await getBlogsByNameFilter();
    } catch (err) {
      error(err);
    } finally {
      setIsBlogLoading(false);
    }
  };

  useEffect(() => {
    blogFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isBlogLoading,
    articles,
    filteredArticles,
  };
}
