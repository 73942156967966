/**
 * Truncates a string if it exceeds the maximum number of characters.
 * @param {string} str - The input string.
 * @param {number} maxLength - The maximum length of the string.
 * @returns {string} - The truncated string, followed by ellipsis if necessary.
 */
export const truncateString = (str: string, maxLength: number): string => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + "...";
  }
  return str;
};
