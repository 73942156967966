import React from "react";
import classnames from "classnames";
import { Link, useLocation } from "react-router-dom";

// types
import type { TabType } from "./Tabs.types";

// styles
import styles from "./Tabs.module.scss";

type TabsProps = {
  tabs: TabType[];
};

export function Tabs({ tabs }: TabsProps) {
  const { pathname: currentURL } = useLocation();

  const isActiveLink = (link: string) => link === currentURL;

  return (
    <div className={styles.container}>
      <div className={styles.linksWrapper}>
        {tabs.map((tab) => (
          <Link
            key={tab.to}
            to={tab.to}
            className={classnames(styles.link, {
              [styles.activeLink]: isActiveLink(tab.to),
            })}
          >
            {tab.title}
          </Link>
        ))}
      </div>
    </div>
  );
}
