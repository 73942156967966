import { z } from "zod";

export const blogSchema = z.object({
  id: z.string(),
  title: z.string(),
  content: z.string(),
  author: z.string(),
  picture: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const allBlogSchema = z.object({
  total: z.number(),
  data: z.array(blogSchema),
});

export const blogFormSchema = blogSchema.pick({
  title: true,
  content: true,
  author: true,
  picture: true,
});
