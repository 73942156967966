import React, { useCallback, useContext, useMemo, useRef } from "react";
import { Editor as TinymceEditor } from "@tinymce/tinymce-react";

// hooks
import { useWindowSize } from "../../hooks/use-window-size/use-window-size";

// consts
import { API_KEY, EDITOR_INIT_SETTINGS } from "./editor.const";

// context
import { errorContext } from "../../context/error/ErrorProvider";
import { localesContext } from "../../context/local-provider/LocalProvider";

//translation
import { t } from "@lingui/macro";

export type TinymceEditorProps = {
  model: string;
  deleteImage?: (image: { id: number; main: number; url: string }) => void;
  onModelChange: (text: string) => void;
};

function Editor({ model, onModelChange }: TinymceEditorProps) {
  const { i18n } = useContext(localesContext);
  const { error } = useContext(errorContext);

  const { width } = useWindowSize();

  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleFilePicker = useCallback(
    (callback: (value: string) => void) => {
      if (inputFileRef.current) {
        inputFileRef.current.click();
        inputFileRef.current.onchange = (event: Event) => {
          const target = event.target as HTMLInputElement;
          const file = target.files?.[0];
          if (file) {
            const reader = new FileReader();
            reader.onload = () => {
              const dataURL = reader.result as string;
              callback(dataURL);
            };
            reader.onerror = (e) => {
              console.error("Error occurred while reading the file:", e);
              error(t(i18n)`Error occurred while reading the file`);
            };
            reader.readAsDataURL(file);
          }
        };
      }
    },
    [inputFileRef]
  );

  const editorInitData = useMemo(() => {
    return {
      ...EDITOR_INIT_SETTINGS,
      file_picker_callback: handleFilePicker,
    };
  }, [EDITOR_INIT_SETTINGS, handleFilePicker]);

  return (
    <div>
      <input type="file" ref={inputFileRef} style={{ display: "none" }} />
      <TinymceEditor
        apiKey={API_KEY}
        onEditorChange={(text) => onModelChange(text)}
        value={model}
        init={{ ...editorInitData, height: width > 768 ? 400 : 270 }}
      />
    </div>
  );
}

export default React.memo(Editor);
