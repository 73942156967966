import React, { SVGProps } from "react";

const CheckIcon = ({
  className,
  color = "#E8E8E8",
  width = 20,
  height = 20,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.7">
        <path
          d="M7.11095 15.9447L1.83317 10.6669C1.61095 10.4447 1.61095 10.0558 1.83317 9.77805L2.6665 8.94472C2.88873 8.72249 3.27761 8.72249 3.55539 8.94472L7.55539 12.9447L16.4443 4.05583C16.6665 3.8336 17.0554 3.8336 17.3332 4.05583L18.1665 4.88916C18.3887 5.11138 18.3887 5.50027 18.1665 5.77805L7.99984 15.9447C7.72206 16.1669 7.33317 16.1669 7.11095 15.9447Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
export { CheckIcon };
