import { useContext, useEffect, useState } from "react";

// context
import { errorContext } from "../../context/error/ErrorProvider";
import { courseContext } from "../../context/course-provider/CourseProvider";
import { membershipContext } from "../../context/membership-provider/MembershipProvider";
import { cartContext } from "../../context/cart-provider/CartProvider";

// types
import type {
  CourseType,
  VideoType,
} from "../../context/course-provider/CourseProvider.types";
import type { MembershipTypesType } from "../../context/membership-provider/MembershipProvider.types";

export function usePreviewModalFetch(isOpen: boolean) {
  const { error } = useContext(errorContext);
  const { coursesData, isCoursesLoading } = useContext(courseContext);
  const { getMembershipsTypes, membershipsTypesData } =
    useContext(membershipContext);
  const { getCartProducts, cartProducts } = useContext(cartContext);

  const [isMembershipLoading, setIsMembershipLoading] = useState(false);

  const [filteredCart, setFilteredCart] = useState<{
    courses: CourseType[];
    videos: VideoType[];
    membership: MembershipTypesType | null;
  } | null>(null);
  const [totalPrice, setTotalPrice] = useState("");

  useEffect(() => {
    if (coursesData && cartProducts && membershipsTypesData) {
      const filteredCourses = coursesData.filter((course) =>
        cartProducts.courses.includes(course.id)
      );

      const allVideos = coursesData.reduce<VideoType[]>((acc, course) => {
        if (course.videos) {
          return acc.concat(course.videos);
        }
        return acc;
      }, []);

      const filteredVideos = allVideos.filter((video) =>
        cartProducts.videos.includes(video.id)
      );

      const currentMemebership = membershipsTypesData.find(
        (membership) => membership.id === cartProducts.membershipId
      );

      setFilteredCart({
        videos: filteredVideos,
        courses: filteredCourses,
        membership: currentMemebership ? currentMemebership : null,
      });

      const coursesTotalWithDisountPrice = filteredCourses.map(
        (cartProduct) => {
          if (cartProduct.discountPrice) {
            return Number(cartProduct.discountPrice);
          }
          return Number(cartProduct.price);
        }
      );

      const coursesTotal = coursesTotalWithDisountPrice.length
        ? coursesTotalWithDisountPrice
            .reduce((acc, currValue) => acc + currValue)
            .toFixed(2)
        : 0;

      const videosTotalWithDisountPrice = filteredVideos.map((cartProduct) => {
        if (cartProduct.discountPrice) {
          return Number(cartProduct.discountPrice);
        }
        return Number(cartProduct.price);
      });

      const videosTotal = videosTotalWithDisountPrice.length
        ? videosTotalWithDisountPrice
            .reduce((acc, currValue) => acc + currValue)
            .toFixed(2)
        : 0;

      const total = (
        Number(coursesTotal) +
        Number(videosTotal) +
        Number(
          currentMemebership
            ? currentMemebership.discountPrice
              ? Number(currentMemebership.discountPrice)
              : Number(currentMemebership.price)
            : (0).toFixed(2)
        )
      ).toFixed(2);

      setTotalPrice(String(total));
    }
  }, [cartProducts, coursesData, membershipsTypesData]);

  const cartFetch = async () => {
    try {
      console.log("hereeeeeeee", isOpen);

      if (isOpen) {
        setIsMembershipLoading(true);

        getCartProducts();

        if (!membershipsTypesData) {
          await getMembershipsTypes();
        }
      }
    } catch (err) {
      error(err);
    } finally {
      setIsMembershipLoading(false);
    }
  };

  const isCartLoading = isMembershipLoading && isCoursesLoading;

  useEffect(() => {
    cartFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return {
    isCartLoading,
    filteredCart,
    totalPrice,
  };
}
