import { z } from "zod";

export const fileSchema = z.object({
  createdAt: z.number(),
  fileName: z.string(),
  fileSize: z.number(),
  key: z.string(),
  extension: z.string(),
});

export const fileDataSchema = z.object({
  state: z.enum(["failed", "success"]),
  file: fileSchema,
});
