import React, { useCallback, useContext } from "react";
import { v4 as uuidv4 } from "uuid";

// hooks
import { useWindowSize } from "../../../../hooks/use-window-size/use-window-size";
import { useCoursesAdminForm } from "../use-courses-admin";

// icons
import { DefaultPictureIcon } from "../../../../assets/icons/DefaultPictureIcon";
import { DeleteIcon } from "../../../../assets/icons/DeleteIcon";
import { ArrowLeftIcon } from "../../../../assets/icons/ArrowLeftIcon";

// context
import { errorContext } from "../../../../context/error/ErrorProvider";
import { localesContext } from "../../../../context/local-provider/LocalProvider";

// components
import Modal from "../../../../components/modal/Modal";
import Button from "../../../../components/button/Button";
import Input from "../../../../components/input/Input";
import Spinner from "../../../../components/spinner/Spinner";

// types
import type { CourseType } from "../../../../context/course-provider/CourseProvider.types";

// styles
import styles from "./CourseAdminModal.module.scss";

//translation
import { t } from "@lingui/macro";
import { Trans } from "@lingui/macro";

type CourseAdminModalProps = {
  course: CourseType | null;
  courseType?: "beginner" | "pro" | "vip" | null;
  isOpen: "create" | "edit" | null;
  onClose: () => void;
};

export function CourseAdminModal({
  course,
  courseType,
  isOpen,
  onClose,
}: CourseAdminModalProps) {
  const { i18n } = useContext(localesContext);
  const { error } = useContext(errorContext);
  const { width } = useWindowSize();

  const {
    courseFormData,
    willLearnBenefits,
    willGetBenefits,
    pictureInputRef,
    isDisabledButton,
    isImageUploading,
    handleChangeCourseAdminData,
    handleChangeBenefitsData,
    handleAddNewBenefit,
    handleDeleteBenefit,
    handleCloseModal,
    onSubmit,
  } = useCoursesAdminForm(isOpen, course, courseType);

  const closeModal = () => {
    onClose();
    handleCloseModal();
  };

  const isCreatingModal = isOpen === "create";

  const modalHeader = isCreatingModal
    ? t(i18n)`Add new course`
    : t(i18n)`Edit course`;

  const handleSubmit = useCallback(
    async (e: any) => {
      try {
        await onSubmit(e);
        onClose();
      } catch (e) {
        error(e);
      }
    },
    [onSubmit, onClose, error]
  );

  return (
    <Modal
      isOpen={Boolean(isOpen)}
      onClose={closeModal}
      header={""}
      width={width > 1024 ? "65%" : width > 767 ? "75%" : "100%"}
      size="fullScreen"
      className={styles.modal}
    >
      <form className={styles.form}>
        <div className={styles.mainContent}>
          <div className={styles.titleWrapper}>
            {width < 768 ? (
              <ArrowLeftIcon
                width={24}
                height={24}
                onClick={closeModal}
                className={styles.backArrow}
              />
            ) : null}

            <p className={styles.title}>{modalHeader}</p>
          </div>

          <div className={styles.courseWrapper}>
            <div className={styles.courseInfo}>
              <div className={styles.pictureWrapper}>
                <input
                  type="file"
                  name="picture"
                  onChange={handleChangeCourseAdminData}
                  ref={pictureInputRef}
                  accept="image/png, image/jpeg"
                  style={{ display: "none" }}
                />

                {isImageUploading ? (
                  <div className={styles.loader}>
                    <Spinner />
                  </div>
                ) : courseFormData.picture ? (
                  <>
                    <img src={courseFormData.picture} alt="video screen" />
                    <Button
                      width="auto"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        pictureInputRef.current?.click();
                      }}
                      className={styles.pictureButton}
                    >
                      <Trans>Change</Trans>
                    </Button>
                  </>
                ) : (
                  <>
                    <div className={styles.iconWrapper}>
                      <DefaultPictureIcon />
                    </div>

                    <Button
                      width="auto"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        pictureInputRef.current?.click();
                      }}
                      className={styles.pictureButton}
                    >
                      <Trans>Add Picture</Trans>
                    </Button>
                  </>
                )}
              </div>

              <div className={styles.inputsWrapper}>
                <Input
                  variant="admin"
                  label={t(i18n)`Course name`}
                  name="title"
                  value={courseFormData.title}
                  onChange={handleChangeCourseAdminData}
                />

                <Input
                  variant="admin"
                  label={t(i18n)`Course price`}
                  name="price"
                  type="number"
                  value={courseFormData.price}
                  onChange={handleChangeCourseAdminData}
                />

                <Input
                  variant="admin"
                  label={t(i18n)`Discount price`}
                  name="discountPrice"
                  type="number"
                  value={courseFormData.discountPrice || ""}
                  onChange={handleChangeCourseAdminData}
                />
              </div>
            </div>
            <div className={styles.benefitsWrapper}>
              <div className={styles.benefitWrapper}>
                <div className={styles.benefitContent}>
                  <p className={styles.benefitTitle}>
                    <Trans>You’ll learn:</Trans>
                  </p>
                  <div className={styles.benefitsList}>
                    {willLearnBenefits.map((benefit, index) => (
                      <div className={styles.inputWrapper} key={benefit.id}>
                        <Input
                          variant="admin"
                          name={benefit.id}
                          label={`${t(i18n)`Benefit`} ${index + 1}`}
                          labelVariant="secondary"
                          value={benefit.value}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeBenefitsData(e, "learn")
                          }
                        />

                        <DeleteIcon
                          className={styles.removeIcon}
                          onClick={(e) =>
                            handleDeleteBenefit(e, benefit.id, "learn")
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>

                <Button
                  variant="outline"
                  onClick={(e) => handleAddNewBenefit(e, uuidv4(), "learn")}
                >
                  <Trans>Add benefits</Trans>
                </Button>
              </div>
              <div className={styles.benefitWrapper}>
                <div className={styles.benefitContent}>
                  <p className={styles.benefitTitle}>
                    <Trans>You’ll get:</Trans>
                  </p>
                  <div className={styles.benefitsList}>
                    {willGetBenefits.map((benefit, index) => (
                      <div className={styles.inputWrapper} key={benefit.id}>
                        <Input
                          variant="admin"
                          name={benefit.id}
                          label={`${t(i18n)`Benefit`} ${index + 1}`}
                          labelVariant="secondary"
                          value={benefit.value}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeBenefitsData(e, "get")
                          }
                        />

                        <DeleteIcon
                          className={styles.removeIcon}
                          onClick={(e) =>
                            handleDeleteBenefit(e, benefit.id, "get")
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>

                <Button
                  variant="outline"
                  onClick={(e) => handleAddNewBenefit(e, uuidv4(), "get")}
                >
                  <Trans>Add benefits</Trans>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.buttonsWrapper}>
          <Button
            width="auto"
            type="submit"
            onClick={handleSubmit}
            disabled={isDisabledButton}
          >
            <Trans>Save</Trans>
          </Button>
        </div>
      </form>
    </Modal>
  );
}
