import React from "react";

// styles
import styles from "../General.module.scss";

//translation
import { Trans } from "@lingui/macro";

export function SubscriptionScreen() {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Trans>
          <h4>Subscription Policy</h4>
          <p>(“Policy”)</p>
          <p>Effective Date: Aug 14, 2024</p>
          <p>
            This Subscription Policy outlines the terms and conditions governing
            your subscription to our Services. By subscribing to our Services,
            you agree to be bound by this Policy.
          </p>
        </Trans>
      </div>

      <Trans>
        <h5>1. Subscription Types</h5>
        <p>
          We offer the following subscription plans: <br /> <br />
          - 3-Month Subscription: Access to all poker educational materials and
          courses (“Services”) for a period of three (3) months. <br />
          - Annual Subscription: Access to all poker educational materials and
          courses for a period of twelve (12) months. <br />
          - 3-Year Subscription: Access to all poker educational materials and
          courses for a period of thirty-six (36) months. <br />
        </p>

        <h5>2. Disclosure of Subscription Terms</h5>
        <p>
          The Company will disclose the full subscription terms simultaneously
          with any request for Card credentials. This disclosure will include:{" "}
          <br />
          - The exact price that will be billed to your Card. <br />
          - The frequency of the billing (e.g., every 3 months, annually, every
          3 years).
          <br /> <br />
          Cardholder's affirmative acceptance of the subscription terms is
          captured before completing the subscription order.
        </p>

        <h5>3. Subscription Order Confirmation</h5>
        <p>
          After the Cardholder completes the subscription order, the Company
          will promptly send a subscription order confirmation to the Cardholder
          through an e-mail message or other electronic communication method
          that includes the subscription terms. <br /> <br />
          The confirmation message will include or provide access to this
          Subscription Policy for account management capabilities, including
          instructions for canceling the subscription (and thereby withdrawing
          permission for any subsequent recurring payment). <br />
          <br />
          For any subscription where the billing frequency is every six months
          (180 days) or less (i.e., billing occurs every six months, every year
          etc.), the Company will send an electronic reminder to the Cardholder
          at least seven days but no more than 30 days prior to the next billing
          date that includes the subscription terms and includes or provides
          access to instructions for account management capabilities, including
          instructions for canceling the subscription (and thereby withdrawing
          permission for any subsequent recurring payment). The Company will
          send you an email in the subject line that relates to upcoming charges
          to the Cardholder (for example, “Important Information About Upcoming
          Charges to Your Account”).
        </p>

        <h5>4. Transaction Receipt</h5>
        <p>
          After receiving an approved authorization request, the Company will
          provide the Cardholder with a transaction receipt through an e-mail
          message or other electronic communication method that includes the
          amount and reason for the billing. <br />
          <br />
          The transaction receipt will also include or provide access to this
          Subscription Policy for account management capabilities, including
          instructions for canceling the subscription (and thereby withdrawing
          permission for any subsequent recurring payment transactions).
          <br />
          <br />
          Cardholders may choose to opt out of receiving these notices.
        </p>

        <h5>5. Cancellation of the Subscription</h5>
        <p>
          Cancellations will take effect at the end of the current subscription
          period. You will retain access to the Services until the end of your
          subscription term. <br />
          <br />
          If you wish to cancel your subscription before the end of the
          subscription term, please write to our email:{" "}
          <a href="mailto:support@pokerpowerhouse.uk">
            support@pokerpowerhouse.uk
          </a>{" "}
          <br />
          <br />
          The Company will provide you with an online or electronic cancellation
          method or clear instructions on how to cancel that are easily
          accessible online. <br />
          <br />
          The amount of compensation is measured depending on the type of
          subscription . All details of the cancellation of the subscription
          will be carried out through the technical support of the website. No
          refunds will be issued for any used portion of a subscription. <br />
          <br />
          <u>Involuntary cancellation:</u> <br />
          <br />
          We reserve the right to cancel your subscription at any time if you
          violate our Terms and Conditions, Subscription policy, or engage in
          any unlawful or fraudulent activities. <br />
          <br />
          In the event of an involuntary cancellation, you will be notified via
          email, and access to the Services will be immediately terminated.
          Refunds for involuntary cancellations are issued at our discretion and
          based on the specific circumstances of the cancellation.
        </p>

        <h5>6. Changes to Subscription Plans</h5>
        <p>
          We reserve the right to modify the subscription fees or features of
          our subscription plans at any time. <br />
          Any changes to the subscription fees or plans will be communicated to
          you at least 10 days before the changes take effect.
          <br />
          If you do not agree to the changes, you may cancel your subscription
          before the changes become effective.
        </p>

        <h5>7. Payment Methods</h5>
        <p>
          We accept the following payment methods: Visa, MasterCard.
          <br />
          By providing your payment information, you authorize us to charge the
          subscription fees to your chosen payment method.
        </p>

        <h5>8. Access to Materials</h5>
        <p>
          Upon successful subscription, you will receive immediate access to all
          Services available under your subscription plan.
          <br />
          Access to the Services is for personal use only and may not be shared
          or distributed.
        </p>

        <h5>9. Account Responsibility</h5>
        <p>
          You are responsible for maintaining the confidentiality of your
          account and login information. You agree to notify us immediately of
          any unauthorized use of your account.
        </p>

        <h5>10. Termination of Subscription</h5>
        <p>
          We reserve the right to terminate your subscription and access to our
          Services if you violate our Terms and Conditions, Subscription policy,
          or engage in any unlawful or fraudulent activities.
        </p>

        <h5>11. Changes to our Subscription Policy</h5>
        <p>
          We reserve the right to update or modify this Subscription Policy at
          any time. Changes to the policy will be posted on our website and will
          take effect immediately upon posting.
        </p>

        <h5>12. Contact Information</h5>
        <p>
          If you have any questions or concerns about this Subscription Policy,
          please contact us at:{" "}
          <a href="mailto:support@pokerpowerhouse.uk">
            support@pokerpowerhouse.uk
          </a>{" "}
          <br />
          <br />
          For more information, visit our website{" "}
          <a
            href="https://pokerpowerhouse.uk"
            target="_blank"
            rel="noopener noreferrer">
            https://pokerpowerhouse.uk
          </a>
        </p>
      </Trans>
    </div>
  );
}
