import React from "react";

// images
import arrow_image from "../../../../assets/images/arrow_middle_background.png";
import mobile_arrow_image from "../../../../assets/images/mobile-arrow_middle_background.png";

// icons
import { CheckIcon } from "../../../../assets/icons/CheckIcon";

// styles
import styles from "./CourseBenefits.module.scss";

type CourseBenefitsProps = {
  title: string;
  benefits: string[];
};

export function CourseBenefits({ title, benefits }: CourseBenefitsProps) {
  return (
    <div className={styles.benefitWrapper}>
      <picture className={styles.backgroundImage}>
        <source media="(min-width: 768px)" srcSet={arrow_image} />

        <img src={mobile_arrow_image} alt="arrow" />
      </picture>

      <h3 className={styles.title}>{title}</h3>
      <div className={styles.benefits}>
        {benefits.map((benefit, index) => (
          <div className={styles.benfit} key={index}>
            <div>
              <CheckIcon />
            </div>

            <p>{benefit}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
