import { z } from "zod";

export const membershipTypesSchema = z.object({
  id: z.string(),
  name: z.enum(["ONE_MONTH", "ONE_YEAR", "THREE_YEARS"]),
  price: z.string(),
  discountPrice: z.string().nullable().optional(),
  options: z.array(z.string()),
});

export const membershipSchema = z.object({
  id: z.string(),
  discountPrice: z.number().nullable().optional(),
  type: membershipTypesSchema,
  options: z.array(z.string()).nullable().optional(),
  startDate: z.string(),
  endDate: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const allMembershipsSchema = z.object({
  total: z.number(),
  data: z.array(membershipSchema),
});

export const allMembershipsTypesSchema = z.object({
  total: z.number(),
  data: z.array(membershipTypesSchema),
});

export const addMembershipFormSchema = z.object({
  name: z.enum(["ONE_MONTH", "ONE_YEAR", "THREE_YEARS"]),
  price: z.number(),
  discountPrice: z.number().nullable().optional(),
  options: z.array(z.string()),
});
