import React, { SVGProps } from "react";

const DeleteIcon = ({
  className,
  color = "#A21916",
  width = 21,
  height = 24,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      viewBox="0 0 21 24"
      fill={color}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_987_31533)">
        <path
          d="M20.25 3.75H16.3884L14.7947 1.08984C14.3484 0.413766 13.6594 0 12.8719 0H8.12812C7.34062 0 6.60937 0.413766 6.20625 1.08984L4.61156 3.75H0.75C0.333984 3.75 0 4.08422 0 4.5V5.25C0 5.66719 0.333984 6 0.75 6H1.5V21C1.5 22.657 2.84297 24 4.5 24H16.5C18.157 24 19.5 22.657 19.5 21V6H20.25C20.6672 6 21 5.66719 21 5.25V4.5C21 4.08422 20.6672 3.75 20.25 3.75ZM8.05781 2.385C8.10469 2.30297 8.19844 2.25 8.29688 2.25H12.7031C12.8027 2.25 12.8965 2.30273 12.9434 2.38477L13.7625 3.75H7.2375L8.05781 2.385ZM16.5 21.75H4.5C4.08577 21.75 3.75 21.4142 3.75 21V6H17.25V21C17.25 21.4125 16.9125 21.75 16.5 21.75ZM10.5 19.5C10.9146 19.5 11.25 19.1646 11.25 18.75V9C11.25 8.58544 10.9146 8.25 10.5 8.25C10.0854 8.25 9.75 8.5875 9.75 9V18.75C9.75 19.1625 10.0875 19.5 10.5 19.5ZM6.75 19.5C7.1625 19.5 7.5 19.1625 7.5 18.75V9C7.5 8.58544 7.16456 8.25 6.75 8.25C6.33544 8.25 6 8.5875 6 9V18.75C6 19.1625 6.3375 19.5 6.75 19.5ZM14.25 19.5C14.6646 19.5 15 19.1646 15 18.75V9C15 8.58544 14.6646 8.25 14.25 8.25C13.8354 8.25 13.5 8.5875 13.5 9V18.75C13.5 19.1625 13.8375 19.5 14.25 19.5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_987_31533">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export { DeleteIcon };
