export const INIT_UPDATE_PROFILE_FORM_DATA = {
  avatar: "",
  firstName: "",
  lastName: "",
  password: "",
  newPassword: "",
  phone: "",
  createdAt: "",
  updatedAt: "",
};
