import React, { SVGProps } from "react";

const ArrowLeftIcon = ({
  className,
  color = "#FDFDFD",
  width = 17,
  height = 17,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 17 17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.4375 7.46875H4.5375L6.91969 5.08656C7.12284 4.88341 7.21875 4.64106 7.21875 4.375C7.21875 3.86762 6.79955 3.34375 6.1875 3.34375C5.9137 3.34375 5.67497 3.44327 5.47594 3.64281L1.37259 7.74616C1.20347 7.91528 1.03125 8.12411 1.03125 8.5C1.03125 8.87589 1.17511 9.05688 1.36434 9.24611L5.47594 13.3572C5.67497 13.5567 5.9137 13.6562 6.1875 13.6562C6.80006 13.6562 7.21875 13.1324 7.21875 12.625C7.21875 12.3589 7.12284 12.1166 6.91969 11.9134L4.5375 9.53125H14.4375C15.0068 9.53125 15.4688 9.06925 15.4688 8.5C15.4688 7.93075 15.0068 7.46875 14.4375 7.46875Z"
        fill={color}
      />
    </svg>
  );
};
export { ArrowLeftIcon };
