import React, { useContext } from "react";

// hooks
import { useResetPasswordForm } from "./use-reset-password-form";

// components
import Input from "../../../components/input/Input";
import Button from "../../../components/button/Button";

//context
import { localesContext } from "../../../context/local-provider/LocalProvider";

// styles
import styles from "./ResetPasswordScreen.module.scss";

//translation
import { t } from "@lingui/macro";
import { Trans } from "@lingui/macro";

export function ResetPasswordScreen() {
  const { i18n } = useContext(localesContext);

  const {
    resetPasswordFormData,
    isDisabledSubmitButton,
    validatePassword,
    validateConfirmPassword,
    handleChangeResetPasswordData,
    onSubmit,
  } = useResetPasswordForm();

  return (
    <section className={styles.section}>
      <form className={styles.form}>
        <Input
          placeholder={t(i18n)`New password`}
          type="password"
          name="password"
          value={resetPasswordFormData.password}
          onChange={handleChangeResetPasswordData}
          validation={validatePassword()}
        />
        <Input
          placeholder={t(i18n)`Re-enter new password`}
          type="password"
          name="confirmPassword"
          value={resetPasswordFormData.confirmPassword}
          onChange={handleChangeResetPasswordData}
          validation={validateConfirmPassword()}
        />
        <div className={styles.buttonWrapper}>
          <Button
            onClick={(e) => onSubmit(e)}
            disabled={isDisabledSubmitButton}>
            <Trans>Submit</Trans>
          </Button>
        </div>
      </form>
    </section>
  );
}
