import { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// consts
import { INIT_RESET_PASSWORD_FORM_DATA } from "./ResetPasswordScreen.consts";
import { PATHS } from "../../../route/route.controls";

// context
import { errorContext } from "../../../context/error/ErrorProvider";
import { userContext } from "../../../context/user-provider/UserProvider";
import { localesContext } from "../../../context/local-provider/LocalProvider";

// types
import { ResetPasswordFormType } from "../../../context/user-provider/UserProvider.types";

//translation
import { t } from "@lingui/macro";

export function useResetPasswordForm() {
  const { i18n } = useContext(localesContext);
  const { error, success } = useContext(errorContext);
  const { resetPassword } = useContext(userContext);

  const navigate = useNavigate();

  const [search] = useSearchParams();

  const resetCode = search.get("resetCode");

  const [resetPasswordFormData, setResetPasswordFormData] =
    useState<ResetPasswordFormType>(INIT_RESET_PASSWORD_FORM_DATA);

  const handleChangeResetPasswordData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      setResetPasswordFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [setResetPasswordFormData, error],
  );

  const onSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      try {
        const resetPasswordData = {
          password: resetPasswordFormData.password,
          resetCode: Number(resetCode),
        };

        await resetPassword(resetPasswordData);
        success(t(i18n)`The password has been successfully changed.`);
        navigate(PATHS.index);
      } catch (e) {
        error(e);
      }
    },
    [resetPasswordFormData, resetCode],
  );

  const validatePassword = () => {
    const passwordRegex =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,20}$/;

    if (resetPasswordFormData) {
      const isValid = passwordRegex.test(resetPasswordFormData.password || "");

      if (isValid || !resetPasswordFormData.password) {
        return {
          error: false,
          message: "",
        };
      } else {
        return {
          error: true,
          message: t(
            i18n,
          )`Password must be more than 7 characters (letters + numbers), and must include at least one uppercase letter.`,
        };
      }
    }
  };

  const validateConfirmPassword = () => {
    if (resetPasswordFormData) {
      if (
        (!validatePassword()?.error ||
          !resetPasswordFormData.confirmPassword) &&
        resetPasswordFormData.confirmPassword === resetPasswordFormData.password
      ) {
        return {
          error: false,
          message: "",
        };
      } else {
        return {
          error: true,
          message: t(i18n)`The passwords must match.`,
        };
      }
    }
  };

  const isDisabledSubmitButton = useMemo(
    () =>
      !resetPasswordFormData?.password ||
      !resetPasswordFormData.confirmPassword,
    [resetPasswordFormData],
  );

  return {
    resetPasswordFormData,
    isDisabledSubmitButton,
    validatePassword,
    validateConfirmPassword,
    handleChangeResetPasswordData,
    onSubmit,
  };
}
