import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// context
import { userContext } from "./UserProvider";
import { errorContext } from "../error/ErrorProvider";
import { sessionContext } from "../session-provider/SessionProvider";
import { localesContext } from "../local-provider/LocalProvider";

// consts
import { PATHS } from "../../route/route.controls";

// types
import type { TypeLoginModal } from "../../side/general/main-screen/login-modal/LoginModal.types";
import type { SignUpUserType } from "./UserProvider.types";

//translation
import { t } from "@lingui/macro";

export function useAuth() {
  const { i18n } = useContext(localesContext);
  const { success, error } = useContext(errorContext);
  const { signUp, logIn, resetPassword, logOut } = useContext(userContext);

  const navigate = useNavigate();

  const [isTypeLoginModal, setIsTypeLoginModal] =
    useState<TypeLoginModal | null>(null);

  const closeLoginModal = useCallback(() => {
    setIsTypeLoginModal(null);
  }, [setIsTypeLoginModal]);

  const openLoginModal = useCallback(
    (type: "auth" | "register" | "reset" | "register_cart") => {
      setIsTypeLoginModal(type);
    },
    [setIsTypeLoginModal]
  );

  const onSubmit = useCallback(
    async (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      formData: SignUpUserType
    ) => {
      try {
        e.preventDefault();

        if (
          isTypeLoginModal === "register" ||
          isTypeLoginModal === "register_cart"
        ) {
          await signUp(formData);
          success(t(i18n)`You have been successfully registered. Please log in`);
          return closeLoginModal();
        }

        if (isTypeLoginModal === "auth") {
          await logIn(formData);
          success(t(i18n)`Welcome`);

          navigate(PATHS.profile);
          return closeLoginModal();
        }

        if (isTypeLoginModal === "reset") {
          const { email } = formData;
          await resetPassword({ email });
          success(`${t(i18n)`Reset password link has been sent to`} ${email}`);
          setIsTypeLoginModal("auth");
        }
      } catch (e) {
        error(e);
      }
    },
    [
      isTypeLoginModal,
      closeLoginModal,
      error,
      navigate,
      resetPassword,
      success,
      signUp,
      logIn,
    ]
  );

  const handleLogOut = useCallback(async () => {
    try {
      await logOut();
    } catch (e) {
      error(e);
    }
  }, [logOut, error]);

  return {
    isTypeLoginModal,
    closeLoginModal,
    openLoginModal,
    onSubmit,
    handleLogOut,
  };
}

export function useAuthFetch() {
  const { error } = useContext(errorContext);
  const { getProfile } = useContext(userContext);
  const { getRoleFromToken } = useContext(sessionContext);

  const role = getRoleFromToken();

  const [isAuthLoading, setIsAuthLoading] = useState(false);

  const profileFetch = async () => {
    try {
      if (role) {
        setIsAuthLoading(true);

        await getProfile();
      }
    } catch (e) {
      error(e);
    } finally {
      setIsAuthLoading(false);
    }
  };

  useEffect(() => {
    profileFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isAuthLoading };
}
