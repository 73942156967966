import React, { SVGProps } from "react";

const CartIcon = ({
  className,
  color = "#FDFDFD",
  width = 22,
  height = 24,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.4915 16.511L18.0135 5.71642C18.0051 4.91951 17.3507 4.27307 16.5476 4.27307H14.1179C13.9208 3.00119 12.8097 2 11.4728 2C10.1355 2 9.02411 3.00119 8.82689 4.27307H6.40054C5.59768 4.27307 4.94353 4.91953 4.93511 5.71644L4.45739 16.5107C4.45699 16.5198 4.45679 16.5291 4.45679 16.5383C4.45679 17.3442 5.11437 18 5.92268 18H17.0263C17.8345 18 18.4921 17.3444 18.4921 16.5385C18.4921 16.5293 18.4919 16.5202 18.4915 16.511ZM11.4728 3.24495C12.1205 3.24495 12.6687 3.67952 12.8447 4.27306H10.1002C10.2762 3.67953 10.8248 3.24495 11.4728 3.24495ZM17.0263 16.7564H5.92268C5.80198 16.7564 5.70677 16.6655 5.70193 16.5485L6.17935 5.76061C6.17976 5.75146 6.17995 5.74072 6.17995 5.73156C6.17995 5.61195 6.27683 5.51669 6.40053 5.51669H8.79061V7.79157C8.79061 8.13535 9.06864 8.41403 9.41242 8.41403C9.75621 8.41403 10.0342 8.13535 10.0342 7.79157V5.51669H12.8983V7.79157C12.8983 8.13535 13.1764 8.41403 13.5201 8.41403C13.8639 8.41403 14.1419 8.13535 14.1419 7.79157V5.51669H16.5476C16.6715 5.51669 16.7686 5.6118 16.7686 5.73141C16.7686 5.74058 16.7688 5.75047 16.7692 5.75965L17.2469 16.5482C17.2421 16.6652 17.1469 16.7564 17.0263 16.7564Z"
        fill={color}
      />
    </svg>
  );
};
export { CartIcon };
