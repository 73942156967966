import React from "react";

// hooks
import { useReviewsConsts } from "./use-reviews-consts";

// components
import CustomReviewItem from "../custom-review-item/CustomReviewItem";
import ReviewItem from "../review-item/ReviewItem";

// styles
import styles from "./Reviews.module.scss";

//translations
import { Trans } from "@lingui/macro";

export function Reviews() {
  const { reviwersData } = useReviewsConsts();
  return (
    <section className={styles.reviewSection}>
      <div>
        <h3 className={styles.reviewTitle}>
          <Trans>Customer testimonials</Trans>
        </h3>
      </div>

      <div className={styles.reviewsWrapper}>
        <CustomReviewItem />

        {reviwersData.map((review, index) => (
          <ReviewItem review={review} key={index} />
        ))}
      </div>
    </section>
  );
}
