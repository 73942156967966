import type { MembershipTypesType } from "../../../context/membership-provider/MembershipProvider.types";

export const INIT_MEMBERSHIPS_TYPES: MembershipTypesType[] = [
  {
    id: "",
    price: "",
    discountPrice: null,
    name: "ONE_MONTH",
    options: [],
  },
  {
    id: "",
    price: "",
    discountPrice: null,
    name: "ONE_YEAR",
    options: [],
  },
  {
    id: "",
    price: "",
    discountPrice: null,
    name: "THREE_YEARS",
    options: [],
  },
];
