import React, { SVGProps } from "react";

const EditIcon = ({
  className,
  color = "#E8E8E8",
  width = 22,
  height = 22,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      viewBox="0 0 22 22"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_987_31530)">
        <path
          d="M10.083 3.66666H3.66634C3.18011 3.66666 2.7138 3.85981 2.36998 4.20363C2.02616 4.54744 1.83301 5.01376 1.83301 5.49999V18.3333C1.83301 18.8196 2.02616 19.2859 2.36998 19.6297C2.7138 19.9735 3.18011 20.1667 3.66634 20.1667H16.4997C16.9859 20.1667 17.4522 19.9735 17.796 19.6297C18.1399 19.2859 18.333 18.8196 18.333 18.3333V11.9167"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.958 2.29168C17.3227 1.92701 17.8173 1.72214 18.333 1.72214C18.8487 1.72214 19.3433 1.92701 19.708 2.29168C20.0727 2.65635 20.2776 3.15096 20.2776 3.66668C20.2776 4.18241 20.0727 4.67701 19.708 5.04168L10.9997 13.75L7.33301 14.6667L8.24967 11L16.958 2.29168Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_987_31530">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export { EditIcon };
