import React, { useContext } from "react";

// components
import { MembershipItem } from "./membership-item/MembershipItem";

// context
import { userContext } from "../../../../context/user-provider/UserProvider";

// styles
import styles from "./Membership.module.scss";

//translation
import { Trans } from "@lingui/macro";

export function Membership() {
  const { userData } = useContext(userContext);

  return (
    <section className={styles.container}>
      {userData?.memberships.length ? (
        <>
          <p className={styles.title}>
            <Trans>Membership</Trans>
          </p>{" "}
          {userData?.memberships.map((membership) => (
            <MembershipItem membership={membership} key={membership.id} />
          ))}
        </>
      ) : null}
    </section>
  );
}
