import React, { SVGProps } from "react";

const SearchIcon = ({
  className,
  color = "#FDFDFD",
  width = 22,
  height = 23,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      viewBox="0 0 22 23"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.5">
        <path
          d="M19.6234 18.6066L15.5805 14.5637C16.5538 13.2679 17.0793 11.6906 17.0775 10.07C17.0775 5.93039 13.7096 2.5625 9.57 2.5625C5.43039 2.5625 2.0625 5.93039 2.0625 10.07C2.0625 14.2096 5.43039 17.5775 9.57 17.5775C11.1906 17.5793 12.7679 17.0538 14.0637 16.0805L18.1066 20.1234C18.3113 20.3063 18.5782 20.404 18.8526 20.3963C19.127 20.3886 19.388 20.2762 19.5821 20.0821C19.7762 19.888 19.8886 19.627 19.8963 19.3526C19.904 19.0782 19.8063 18.8113 19.6234 18.6066ZM4.2075 10.07C4.2075 9.0094 4.52201 7.97261 5.11124 7.09075C5.70048 6.2089 6.53799 5.52157 7.51786 5.1157C8.49773 4.70982 9.57595 4.60363 10.6162 4.81054C11.6564 5.01745 12.6119 5.52818 13.3619 6.27814C14.1118 7.0281 14.6225 7.9836 14.8295 9.02383C15.0364 10.0641 14.9302 11.1423 14.5243 12.1221C14.1184 13.102 13.4311 13.9395 12.5492 14.5288C11.6674 15.118 10.6306 15.4325 9.57 15.4325C8.1483 15.4308 6.78532 14.8653 5.78002 13.86C4.77473 12.8547 4.20921 11.4917 4.2075 10.07Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
export { SearchIcon };
