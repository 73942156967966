import React, { useContext } from "react";

// consts
import { INIT_MEMBERSHIPS_TYPES } from "./MemberShipAdminScreen.consts";

// helpers
import { sortMemberships } from "../../../helpers/sort-memberships";

// hooks
import { useMembershipAdminFetch } from "./use-membership-admin";

// components
import MembershipAdminItem from "./membership-admin-item/MembershipAdminItem";
import Spinner from "../../../components/spinner/Spinner";

// context
import { membershipContext } from "../../../context/membership-provider/MembershipProvider";

// styles
import styles from "./MemberShipAdminScreen.module.scss";

export function MembershipAdminScreen() {
  const { adminMembershipsTypesData } = useContext(membershipContext);

  const { isMembershipsAdminLoading } = useMembershipAdminFetch();

  const membershipsData = sortMemberships(
    INIT_MEMBERSHIPS_TYPES.map((initItem, index) => {
      return adminMembershipsTypesData && adminMembershipsTypesData[index]
        ? adminMembershipsTypesData[index]
        : initItem;
    })
  );

  if (isMembershipsAdminLoading) {
    return (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  }

  return (
    <section className={styles.membershipSection}>
      {membershipsData.map((membership) => (
        <MembershipAdminItem
          key={membership.id ? membership.id : membership.name}
          membership={membership}
        />
      ))}
    </section>
  );
}
