import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";

// hooks
import { useCoursesAdminScreenConsts } from "./use-courses-admin-screen-consts";

// context
import { errorContext } from "../../../context/error/ErrorProvider";
import { courseContext } from "../../../context/course-provider/CourseProvider";
import { storageContext } from "../../../context/storage-provider/StorageProvider";
import { localesContext } from "../../../context/local-provider/LocalProvider";

// types
import type { AddCourseFormType } from "../../../context/course-provider/CourseProvider.types";
import type { CourseType } from "./../../../context/course-provider/CourseProvider.types";

//translation
import { t } from "@lingui/macro";

export function useCoursesAdminForm(
  modalType: "create" | "edit" | null,
  course: CourseType | null,
  courseType?: "beginner" | "pro" | "vip" | null,
) {
  const { INIT_COURSE_ADMIN_FORM_DATA } = useCoursesAdminScreenConsts();

  const { i18n } = useContext(localesContext);
  const { error, success } = useContext(errorContext);
  const { addCourse, updateCourse, deleteCourse } = useContext(courseContext);
  const { uploadImage } = useContext(storageContext);

  const pictureInputRef = useRef<any>(null);

  const [isImageUploading, setIsImageUploading] = useState(false);
  const [courseFormData, setCourseFormData] = useState<AddCourseFormType>(
    INIT_COURSE_ADMIN_FORM_DATA,
  );
  const [willLearnBenefits, setWillLearnBenefits] = useState<
    { id: string; value: string }[]
  >([]);
  const [willGetBenefits, setWillGetBenefits] = useState<
    { id: string; value: string }[]
  >([]);

  useEffect(() => {
    if (course && modalType === "edit") {
      const {
        title,
        price,
        discountPrice,
        picture,
        isPublished,
        category,
        willLearn,
        willGet,
      } = course;

      setCourseFormData({
        title,
        price: price ? String(price) : "",
        discountPrice: discountPrice ? String(discountPrice) : "",
        picture,
        willLearn,
        willGet,
        isPublished,
        category,
      });

      setWillLearnBenefits(
        willLearn
          ? willLearn.map((benefit) => ({
              id: uuidv4(),
              value: benefit,
            }))
          : [],
      );
      setWillGetBenefits(
        willGet
          ? willGet.map((benefit) => ({
              id: uuidv4(),
              value: benefit,
            }))
          : [],
      );
    }
  }, [setCourseFormData, course, modalType]);

  const handleChangeCourseAdminData = useCallback(
    async (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
      try {
        const { name, value } = e.target;

        if (name === "picture") {
          const file = pictureInputRef.current.files[0];

          const allowedTypes = ["image/png", "image/jpeg"];

          if (!allowedTypes.includes(file.type)) {
            return error(
              t(i18n)`The file type is incorrect. Please upload a PNG or JPEG file.`,
            );
          }

          if (file.size > 2200000) {
            return error(
              t(i18n)`The image size is too large. Please try using a different image under 2MB`,
            );
          }
          setIsImageUploading(true);
          const result = await uploadImage(file, "course");

          if (result) {
            return setCourseFormData((prev) => ({
              ...prev,
              [name]: result,
            }));
          }
        }

        setCourseFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      } catch (e) {
        error(e);
      } finally {
        setIsImageUploading(false);
      }
    },
    [setCourseFormData, error],
  );

  const handleChangeBenefitsData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, benefitType: "learn" | "get") => {
      const { name, value } = e.target;

      if (benefitType === "learn") {
        return setWillLearnBenefits((prev) =>
          prev.map((benefit) => {
            if (benefit.id === name) {
              return {
                ...benefit,
                value,
              };
            } else {
              return benefit;
            }
          }),
        );
      }

      if (benefitType === "get") {
        return setWillGetBenefits((prev) =>
          prev.map((benefit) => {
            if (benefit.id === name) {
              return {
                ...benefit,
                value,
              };
            } else {
              return benefit;
            }
          }),
        );
      }
    },

    [setWillLearnBenefits, setWillGetBenefits],
  );

  const handleAddNewBenefit = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      id: string,
      benefitType: "learn" | "get",
    ) => {
      e.preventDefault();

      if (benefitType === "learn") {
        return setWillLearnBenefits((prev) => [...prev, { id, value: "" }]);
      }

      return setWillGetBenefits((prev) => [...prev, { id, value: "" }]);
    },
    [setWillLearnBenefits, setWillGetBenefits],
  );

  const handleDeleteBenefit = useCallback(
    (
      e: React.MouseEvent<SVGSVGElement, MouseEvent>,
      id: string,
      benefitType: "learn" | "get",
    ) => {
      e.preventDefault();

      if (benefitType === "learn") {
        return setWillLearnBenefits((prev) =>
          prev.filter((benefit) => benefit.id !== id),
        );
      }

      return setWillGetBenefits((prev) =>
        prev.filter((benefit) => benefit.id !== id),
      );
    },
    [setWillLearnBenefits],
  );

  const onSubmit = useCallback(
    async (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      try {
        e.preventDefault();

        const { title, price, picture, isPublished, category, discountPrice } =
          courseFormData;

        if (course && modalType === "edit") {
          // edit
          await updateCourse(course.id, {
            title,
            price,
            picture: picture.split("/").pop() || "",
            isPublished: true,
            category,
            discountPrice: discountPrice ? discountPrice : null,
            willLearn: willLearnBenefits
              .map((benefit) => benefit.value)
              .filter((benefit) => benefit),
            willGet: willGetBenefits
              .map((benefit) => benefit.value)
              .filter((benefit) => benefit),
          });
          success(t(i18n)`The course has been successfully edited.`);
        } else {
          // create
          if (courseType) {
            const courseResponse = await addCourse({
              title,
              price,
              picture: picture.split("/").pop() || "",
              isPublished,
              category: courseType,
              discountPrice: discountPrice ? discountPrice : null,
              willLearn: willLearnBenefits
                .map((benefit) => benefit.value)
                .filter((benefit) => benefit),
              willGet: willGetBenefits
                .map((benefit) => benefit.value)
                .filter((benefit) => benefit),
            });

            success(t(i18n)`The course has been successfully created.`);

            if (courseResponse) return courseResponse.id;
          }
        }
      } catch (e) {
        error(e);
      }
    },
    [courseFormData, willLearnBenefits, willGetBenefits, error, success],
  );

  const handleRemoveCourse = useCallback(
    async (courseId?: string) => {
      try {
        if (courseId) {
          await deleteCourse(courseId);
          success(t(i18n)`The course has been successfully deleted`);
        }
      } catch (e) {
        throw e;
      }
    },
    [deleteCourse, success],
  );

  const handleCloseModal = () => {
    setCourseFormData(INIT_COURSE_ADMIN_FORM_DATA);
    setWillLearnBenefits([]);
    setWillGetBenefits([]);
  };

  const isDisabledButton = useMemo(
    () =>
      !courseFormData.title || !courseFormData.price || !courseFormData.picture,
    [courseFormData],
  );

  return {
    courseFormData,
    willLearnBenefits,
    willGetBenefits,
    pictureInputRef,
    isDisabledButton,
    isImageUploading,
    handleChangeCourseAdminData,
    handleChangeBenefitsData,
    handleRemoveCourse,
    handleAddNewBenefit,
    handleDeleteBenefit,
    handleCloseModal,
    onSubmit,
  };
}

export function useCoursesAdminFetch() {
  const { error } = useContext(errorContext);
  const { getAdminCourses, adminCoursesData, isCoursesLoading } =
    useContext(courseContext);

  const [beginnerCoursesData, setBeginnerCoursesData] = useState<CourseType[]>(
    [],
  );
  const [vipCoursesData, setVipCoursesData] = useState<CourseType[]>([]);
  const [proCoursesData, setProCoursesData] = useState<CourseType[]>([]);

  useEffect(() => {
    const beginnerCourses = adminCoursesData?.filter(
      (course) => course.category === "beginner",
    );

    if (beginnerCourses) {
      setBeginnerCoursesData(beginnerCourses);
    }

    const vipCourses = adminCoursesData?.filter(
      (course) => course.category === "vip",
    );

    if (vipCourses) {
      setVipCoursesData(vipCourses);
    }
    const proCourses = adminCoursesData?.filter(
      (course) => course.category === "pro",
    );

    if (proCourses) {
      setProCoursesData(proCourses);
    }
  }, [adminCoursesData]);

  const adminCoursesFetch = async () => {
    try {
      await getAdminCourses();
    } catch (err) {
      error(err);
    }
  };

  useEffect(() => {
    adminCoursesFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isCoursesLoading,
    beginnerCoursesData,
    vipCoursesData,
    proCoursesData,
  };
}
