import React from "react";
import { format, parseISO } from "date-fns";

// compoents
import { Introduction } from "./introduction/Introduction";
import { LearningBlock } from "./learning-block/LearningBlock";
import { Reviews } from "./reviews/Reviews";
import { Discount } from "./discount/Discount";
import { Questions } from "./questions/Questions";

// styles
import styles from "./Main.screen.module.scss";

export function MainScreen() {
  return (
    <div className={styles.container}>
      <Introduction />

      <LearningBlock />

      <Reviews />

      <Questions />
    </div>
  );
}
