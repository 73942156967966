import React from "react";

// styles
import styles from "../General.module.scss";

//translation
import { Trans } from "@lingui/macro";

export function RefundPolicyScreen() {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Trans>
          <h4>Refund Policy</h4>
          <h6>08.08.2024</h6>
          <p>
            We strive to ensure the highest quality of educational materials and
            courses (hereinafter - “Services”) provided on our website. If for
            any reason you are not satisfied with your purchase, please review
            our Refund Policy (hereinafter - “Policy”).
          </p>
        </Trans>
      </div>

      <Trans>
        <h5>1. Conditions for Refund</h5>
        <p>
          1.1. The User can request a refund only if the request is made within
          one week of the completion of the order, provided that you have not
          started using the educational materials or courses (e.g., you have not
          completed more than 10% of the course).
        </p>

        <p>
          1.2. Refund requests must be submitted to our support team via email
          at{" "}
          <a href="mailto:support@pokerpowerhouse.uk">
            support@pokerpowerhouse.uk
          </a>{" "}
          , including your order number and the reason for the refund.
        </p>

        <h5>2. Refund Process</h5>
        <p>
          2.1. Refund requests will be reviewed within 5 business days of
          receipt.
        </p>
        <p>
          2.2. If the refund is approved, the funds will be returned to your
          original payment method or your Account within 7-10 business days.
        </p>
        <p>
          2.3. If the refund is denied, you will receive an explanation of the
          reasons for the denial and recommendations for further actions.
        </p>

        <h5>3. Exceptions and Special Conditions</h5>
        <p>
          3.1. Purchases made during promotions or with discounts are not
          eligible for refunds.
        </p>
        <p>
          3.2. Refunds for courses that have been fully completed will not be
          issued.
        </p>
        <p>
          3.3. In the event of any technical issues or content-related problems,
          please contact our support team{" "}
          <a href="mailto:support@pokerpowerhouse.uk">
            support@pokerpowerhouse.uk
          </a>{" "}
          for assistance before requesting a refund.
        </p>
        <p>
          3.4. Please keep in mind that ZENIT MARKETING LTD will not refund
          money if you are not satisfied with the type or style of training
          course. We provide refunds in case of a mistake: if you received
          another Service for any technical reason.
        </p>
        <p>
          3.5. At our discretion, if we believe you are abusing our Refund
          Policy, such as if you’ve consumed a significant portion of the
          training course that you want to refund or if you’ve previously
          refunded our Services, we reserve the right to deny your refund,
          restrict you from other future refunds, ban your Account, and/or
          restrict all future use of the Services.
        </p>

        <h5>4. Your Consent</h5>
        <p>
          By using our website, creating a Personal Account (“Account”), making
          a purchase, you hereby consent to our Refund Policy and agree to its
          terms.
        </p>

        <h5>5. Changes to our Refund Policy</h5>
        <p>
          We reserve the right to modify this Refund Policy at any time. Any
          changes to this Policy will be posted on our website. It is your
          responsibility to review this Policy periodically for any updates or
          changes. Continued use of our Services after any modifications
          constitutes your acceptance of the revised Policy. If you do not want
          to agree to this or any updated Refund Policy, you can delete your
          Account.
        </p>

        <h5>Contact Us</h5>
        <p>
          If you have any questions or need further assistance, please contact
          us at{" "}
          <a href="mailto:support@pokerpowerhouse.uk">
            support@pokerpowerhouse.uk
          </a>
        </p>
        <p>
          For more information, visit our website{" "}
          <a href="https://pokerpowerhouse.uk/">https://pokerpowerhouse.uk/</a>
        </p>
      </Trans>
    </div>
  );
}
