import React from "react";
import { useNavigate } from "react-router-dom";

// hooks
import { useWindowSize } from "../../hooks/use-window-size/use-window-size";

// components
import Modal from "../modal/Modal";
import { CartModalCourse } from "./cart-modal-course/CartModalCourse";
import { CartModalOrder } from "./cart-modal-order/CartModalOrder";
import { CartModalMembership } from "./cart-modal-membership/CartModalMembership";
import Button from "../button/Button";

// consts
import { PATHS } from "../../route/route.controls";

// types
import type {
  CourseType,
  VideoType,
} from "../../context/course-provider/CourseProvider.types";
import type { MembershipTypesType } from "../../context/membership-provider/MembershipProvider.types";

// styles
import styles from "./CartModal.module.scss";
import { CartModalVideo } from "./cart-modal-video/CartModalVideo";

//translation
import { Trans } from "@lingui/macro";

type CartModalProps = {
  isOpen: boolean;
  cartProducts: {
    courses: CourseType[];
    videos: VideoType[];
    membership: MembershipTypesType | null;
  } | null;
  total: string;
  onClose: () => void;
  openLoginModal?: (type: "auth" | "register" | "register_cart") => void;
};

export function CartModal({
  isOpen,
  cartProducts,
  total,
  onClose,
  openLoginModal,
}: CartModalProps) {
  const navigate = useNavigate();

  const { width } = useWindowSize();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <div className={styles.titleWrapper}>
          <p className={styles.title}>
            <Trans>My order</Trans>
          </p>
          <p className={styles.description}>
            <Trans>Review and edit your order, then proceed to Checkout.</Trans>
          </p>
        </div>
      }
      className={styles.modal}
      size="fullScreen"
      width={
        width > 1440
          ? "70%"
          : width > 1024
          ? "95%"
          : width > 768
          ? "85%"
          : "100%"
      }>
      <form className={styles.form}>
        {cartProducts ? (
          <div>
            {!cartProducts.courses.length &&
            !cartProducts.membership &&
            !cartProducts.videos.length ? (
              <div className={styles.emptyCart}>
                <h3>
                  <Trans>Your cart is empty</Trans>
                </h3>
                <p>
                  <Trans>But you can choose something for yourself</Trans>
                </p>
                <div>
                  <Button onClick={() => navigate(PATHS.index)} width="auto">
                    <Trans>Go to main page</Trans>
                  </Button>
                </div>
              </div>
            ) : null}

            {cartProducts.courses.length ||
            cartProducts.membership ||
            cartProducts.videos.length ? (
              <div>
                <div className={styles.cartWrapper}>
                  <div className={styles.booksWrapper}>
                    {cartProducts.courses.length
                      ? cartProducts.courses.map((course) => {
                          return (
                            <CartModalCourse
                              cartProduct={course}
                              key={course.id}
                            />
                          );
                        })
                      : null}

                    {cartProducts.videos.length
                      ? cartProducts.videos.map((video) => {
                          return (
                            <CartModalVideo
                              cartProduct={video}
                              key={video.id}
                            />
                          );
                        })
                      : null}

                    {cartProducts.membership ? (
                      <CartModalMembership
                        cartProduct={cartProducts.membership}
                        key={cartProducts.membership.id}
                      />
                    ) : null}
                  </div>

                  <CartModalOrder
                    total={total}
                    courses={cartProducts.courses}
                    membership={cartProducts.membership}
                    videos={cartProducts.videos}
                    onCloseModal={onClose}
                    openLoginModal={openLoginModal}
                  />
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </form>
    </Modal>
  );
}
