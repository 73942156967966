import React, { FunctionComponent, useMemo } from "react";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

// consts
import { REFRESH_TOKEN_NAME, TOKEN_NAME } from "./SessionProvider.consts";

// types
import type {
  SessionContext,
  SessionProviderProps,
} from "./SessionProvider.types";

export const sessionContext = React.createContext({} as SessionContext);

export const SessionProvider: FunctionComponent<SessionProviderProps> = (
  props
) => {
  const { children } = props;

  const getSessionCookie = () => {
    const accessToken = Cookies.get(TOKEN_NAME);

    return accessToken;
  };

  const getRefreshSessionCookie = () => {
    const refreshAccessToken = Cookies.get(REFRESH_TOKEN_NAME);

    return refreshAccessToken;
  };

  const setSessionCookie = (
    token: string | null,
    expires: number | Date = 30
  ) => {
    if (token) Cookies.set(TOKEN_NAME, token, { expires });
  };

  const setRefreshSessionCookie = (
    token: string | null,
    expires: number | Date = 30
  ) => {
    if (token) Cookies.set(REFRESH_TOKEN_NAME, token, { expires });
  };

  const removeSessionCookie = () => {
    Cookies.remove(TOKEN_NAME);
    Cookies.remove(REFRESH_TOKEN_NAME);
  };

  const getRoleFromToken = () => {
    const accessToken = getSessionCookie();

    if (accessToken) {
      const decodedToken = jwtDecode(accessToken) as { role: "admin" | "user" };

      return decodedToken.role;
    }

    return null;
  };

  const contextValue = useMemo(
    () => ({
      getSessionCookie,
      getRefreshSessionCookie,
      setSessionCookie,
      setRefreshSessionCookie,
      removeSessionCookie,
      getRoleFromToken,
    }),
    [
      getSessionCookie,
      getRefreshSessionCookie,
      setSessionCookie,
      setRefreshSessionCookie,
      removeSessionCookie,
      getRoleFromToken,
    ]
  );

  return (
    <sessionContext.Provider value={contextValue}>
      {children}
    </sessionContext.Provider>
  );
};
