import React, { SVGProps } from "react";

const DefaultVideoIcon = ({
  className,
  color = "#000000",
  width = 49,
  height = 49,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 49 49"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1199_63952)">
        <path
          d="M34.5 21.5V14.5C34.5 13.4 33.6 12.5 32.5 12.5H8.5C7.4 12.5 6.5 13.4 6.5 14.5V34.5C6.5 35.6 7.4 36.5 8.5 36.5H32.5C33.6 36.5 34.5 35.6 34.5 34.5V27.5L42.5 35.5V13.5L34.5 21.5ZM28.5 26.5H22.5V32.5H18.5V26.5H12.5V22.5H18.5V16.5H22.5V22.5H28.5V26.5Z"
          fill={color}
          fillOpacity="0.3"
        />
      </g>
      <defs>
        <clipPath id="clip0_1199_63952">
          <rect
            width="48"
            height="48"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export { DefaultVideoIcon };
