import React from "react";
import { Outlet } from "react-router-dom";

// components
import { Header } from "../../components/header/Header";
import { Footer } from "../../components/footer/Footer";

// styles
import styles from "./UserLayout.module.scss";

export function UserLayout(): JSX.Element {
  return (
    <div className={styles.mainSection}>
      <Header type="user" />
      <div className={styles.mainContent}>
        <Outlet />
      </div>

      <Footer />
    </div>
  );
}
