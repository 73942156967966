import React, { useContext, useState } from "react";
import classnames from "classnames";

// images
import default_background_image_src from "../../../../assets/images/default_video_background.jpg";

// hooks
import { useVideoAdminForm } from "../../../admin/courses-admin-screen/video-admin-modal/use-video-admin";

// contexts
import { cartContext } from "../../../../context/cart-provider/CartProvider";
import { currencyContext } from "../../../../context/currency-provider/CurrencyProvider";

// components
import Button from "../../../../components/button/Button";
import { ConfirmModal } from "../../../../components/confirm-modal/ConfirmModal";

// types
import { VideoType } from "../../../../context/course-provider/CourseProvider.types";

// styles
import styles from "./VideoItem.module.scss";

//translation
import { Trans } from "@lingui/macro";

type VideoItemProps = {
  video: VideoType;
  courseId?: string;
  type?: "admin" | "guest" | "user";
  videoIndex: number;
  setSelectedVideo?: React.Dispatch<VideoType | null>;
  setIsOpenEditModal?: React.Dispatch<
    React.SetStateAction<"create" | "edit" | null>
  >;
  setIsOpenVideoModal?: React.Dispatch<React.SetStateAction<VideoType | null>>;
};

export function VideoItem({
  video,
  courseId,
  type = "guest",
  videoIndex,
  setSelectedVideo,
  setIsOpenEditModal,
  setIsOpenVideoModal,
}: VideoItemProps) {
  const { addVideoToCart } = useContext(cartContext);
  const { getCurrencySymbol } = useContext(currencyContext);

  const { handleRemoveVideo } = useVideoAdminForm(courseId || "");

  const [isOpenRemovingVideoModal, setIsOpenRemovingVideoModal] =
    useState(false);

  const isAdminCourse = type === "admin";
  const isUserCourse = type === "user";
  const isGuestCourse = type === "guest";

  const handleOpenVideoModal = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (setIsOpenVideoModal) {
      e.stopPropagation();
      setIsOpenVideoModal(video);
    }
  };

  const handleEditVideo = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    if (setSelectedVideo && setIsOpenEditModal) {
      setSelectedVideo(video);

      setIsOpenEditModal("edit");
    }
  };

  const handleRemoveCurrentVideo = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    setIsOpenRemovingVideoModal(true);
  };

  return (
    <>
      <div className={styles.video}>
        <div
          className={classnames(
            styles.videoPlayer,
            isAdminCourse || isUserCourse ? styles.clickablePlayer : "",
          )}
          onClick={
            isAdminCourse || isUserCourse ? handleOpenVideoModal : undefined
          }>
          <img
            src={video.picture ? video.picture : default_background_image_src}
            alt={video.title}
          />
        </div>
        <div className={styles.videoInfo}>
          <div className={styles.videoTitleWrapper}>
            <p className={styles.videoTitle}>
              <span>
                <Trans>Video</Trans> {videoIndex + 1}:
              </span>{" "}
              {video.title}
            </p>
            {(video.price || video.discountPrice) && !isUserCourse ? (
              <div className={styles.pricesWrapper}>
                {video.discountPrice ? (
                  <p>
                    {isAdminCourse ? "€" : getCurrencySymbol()}{" "}
                    {video.discountPrice}
                  </p>
                ) : null}
                <div className={video.discountPrice ? styles.diagonalLine : ""}>
                  <p className={video.discountPrice ? styles.oldPrice : ""}>
                    {isAdminCourse ? "€" : getCurrencySymbol()} {video.price}
                  </p>
                </div>
              </div>
            ) : null}
          </div>
          <p className={styles.description}>{video.description}</p>
        </div>
        {isAdminCourse ? (
          <div className={styles.buttonsWrapper}>
            <Button onClick={handleEditVideo}>
              <Trans>Edit</Trans>
            </Button>

            <Button onClick={handleRemoveCurrentVideo} variant="outline">
              <Trans>Delete</Trans>
            </Button>
          </div>
        ) : video.price && isGuestCourse ? (
          <div className={styles.buttonsWrapper}>
            <Button
              onClick={() => addVideoToCart(video.id)}
              className={styles.buyButton}>
              <Trans>Add to cart</Trans>
            </Button>
          </div>
        ) : null}
      </div>

      <ConfirmModal
        isOpen={isOpenRemovingVideoModal}
        modalTitle="Delete video"
        modalText={
          <>
            <p className={styles.removingText}>
              <Trans>Are you sure to delete</Trans>
            </p>
            <p className={styles.removingVideoText}>
              <Trans>Video</Trans> {videoIndex + 1}: {video.title}?
            </p>
          </>
        }
        onClose={() => setIsOpenRemovingVideoModal(false)}
        onSubmit={() => handleRemoveVideo(video.id)}
      />
    </>
  );
}
