import React, { useCallback, useContext } from "react";
import { generatePath, useNavigate } from "react-router-dom";

// context
import { cartContext } from "../../../context/cart-provider/CartProvider";
import { currencyContext } from "../../../context/currency-provider/CurrencyProvider";

// components
import Button from "../../button/Button";

// consts
import { PATHS } from "../../../route/route.controls";

// types
import type { CourseType } from "../../../context/course-provider/CourseProvider.types";

// styles
import styles from "./CourseMenuItem.module.scss";

//translation
import { Trans } from "@lingui/macro";

type CourseMenuItemProps = {
  course: CourseType;
};

export function CourseMenuItem({ course }: CourseMenuItemProps) {
  const { addCourseToCart } = useContext(cartContext);
  const { getCurrencySymbol } = useContext(currencyContext);

  const navigate = useNavigate();

  const generatedLink = generatePath(PATHS.course, {
    courseId: course.id,
  });

  const handleAddCourseToCart = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      selectedCourse: CourseType,
    ) => {
      e.preventDefault();
      e.stopPropagation();
      addCourseToCart(selectedCourse);
    },
    [addCourseToCart],
  );

  const handleNavigateToCurrentCourse = () => {
    navigate(generatedLink);
  };

  return (
    <div className={styles.container} onClick={handleNavigateToCurrentCourse}>
      <div className={styles.rearBlock}>
        <img src={course.picture} alt={course.title} />
      </div>

      <div className={styles.titleWrapper}>
        <p className={styles.title}>{course.title}</p>
        <div className={styles.pricesWrapper}>
          <div className={course.discountPrice ? styles.diagonalLine : ""}>
            <p className={course.discountPrice ? styles.oldPrice : ""}>
              {getCurrencySymbol()} {course.price}
            </p>
          </div>
          {course.discountPrice ? (
            <p>
              {getCurrencySymbol()} {course.discountPrice}
            </p>
          ) : null}
        </div>
        <div className={styles.info}>
          {course.videos?.length} <Trans>Videos</Trans>
        </div>
      </div>

      <div className={styles.frontBlock} />

      <Button
        className={styles.button}
        onClick={(e) => handleAddCourseToCart(e, course)}>
        <Trans>Add to cart</Trans>
      </Button>
    </div>
  );
}
