export const API_KEY = "l6sbfv7jjmackcpcg4b52ch1lbklcg9vlp07rj92zoj2fpri";

export const EDITOR_INIT_SETTINGS = {
  plugins: "image lists paste",
  toolbar:
    "undo redo | formatselect | underline | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | image",
  skin: "oxide-dark",
  content_css: "dark",
  images_upload_url: "",
  file_picker_types: "image",
};
