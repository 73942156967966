import { Language, Languages } from "./LocaleProvider.types";

export const languages: Languages = [
  {
    label: "EN",
    value: "en",
  },
  {
    label: "UK",
    value: "uk",
  },
  // {
  //   label: "FR",
  //   value: "fr",
  // },
  // {
  //   label: "DE",
  //   value: "de",
  // },
];

export const defaultLanguage: Language = languages[0].value;
