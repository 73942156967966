import React, { FunctionComponent, useContext, useMemo, useState } from "react";
import { z } from "zod";

// context
import { apiContext } from "../api-provider/ApiProvider";

// consts
import { API_URL_BLOG } from "./BlogProvider.consts";

// schemas
import { allBlogSchema, blogSchema } from "./BlogProvider.schemas";

// types
import type {
  BlogContext,
  BlogFormType,
  BlogProviderProps,
  BlogType,
} from "./BlogProvider.types";

export const blogContext = React.createContext({} as BlogContext);

export const BlogProvider: FunctionComponent<BlogProviderProps> = (props) => {
  const { api } = useContext(apiContext);

  const { children } = props;

  const [blogsData, setBlogsData] = useState<BlogType[] | null>(null);
  const [filteredbBogsData, setFilteredbBogsData] = useState<BlogType[] | null>(
    null
  );
  const [blogById, setBlogById] = useState<BlogType | null>(null);

  const getBlogs = async () => {
    try {
      const response = await api(API_URL_BLOG, {}, allBlogSchema);

      if (response) {
        setBlogsData(response.data);
        return response.data;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const getBlogsByNameFilter = async (filterTitle?: string) => {
    try {
      const apiURL = `${API_URL_BLOG}${
        filterTitle?.trim() ? `?&filter[title]=${filterTitle.trim()}` : ""
      }`;

      const allBlogs = await api(apiURL, {}, allBlogSchema);

      if (allBlogs) {
        return setFilteredbBogsData(allBlogs.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const getBlogById = async (blogId: string) => {
    try {
      const response = await api(`${API_URL_BLOG}/${blogId}`, {}, blogSchema);

      if (response) {
        const currentBlog = response;

        setBlogById(currentBlog);
        return currentBlog;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const createBlog = async (blogForm: BlogFormType) => {
    try {
      if (blogsData) {
        const blogResponse = await api(
          API_URL_BLOG,
          {
            method: "POST",
            data: blogForm,
          },
          blogSchema
        );

        if (blogResponse) {
          const updatedBlogs = [blogResponse, ...blogsData];

          setBlogsData(updatedBlogs);
          return blogResponse;
        }
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const editBlog = async (blogId: string, blogForm: BlogFormType) => {
    try {
      if (blogsData) {
        const updatedItem = await api(`${API_URL_BLOG}/${blogId}`, {
          method: "PUT",
          data: blogForm,
        });

        if (updatedItem) {
          const updatedBlogs = blogsData.map((blog) =>
            blog.id === blogId ? { ...blog, ...updatedItem } : blog
          );

          setBlogsData(updatedBlogs);
        }

        return null;
      }
    } catch (error) {
      throw error;
    }
  };

  const deleteBlog = async (blogId: string) => {
    try {
      if (blogsData) {
        await api(`${API_URL_BLOG}/${blogId}`, { method: "DELETE" });

        const updatedBlogs = blogsData.filter((blog) => blog.id !== blogId);

        setBlogsData(updatedBlogs);
        return updatedBlogs;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const contextValue = useMemo(
    () => ({
      getBlogs,
      getBlogsByNameFilter,
      getBlogById,
      createBlog,
      editBlog,
      deleteBlog,
      blogsData,
      filteredbBogsData,
      blogById,
    }),
    [
      getBlogs,
      getBlogById,
      createBlog,
      editBlog,
      deleteBlog,
      blogsData,
      filteredbBogsData,
      blogById,
    ]
  );

  return (
    <blogContext.Provider value={contextValue}>{children}</blogContext.Provider>
  );
};
