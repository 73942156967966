import React, { useCallback, useRef } from "react";

// compoents
import { Introduction } from "../main-screen/introduction/Introduction";
import { Plans } from "./plans/Plans";
import { Reviews } from "../main-screen/reviews/Reviews";
import { Questions } from "../main-screen/questions/Questions";

// styles
import styles from "./Membership.screen.module.scss";

export function MembershipScreen() {
  const plansRef = useRef<HTMLDivElement | null>(null);

  const handleNavigateToPlans = useCallback(() => {
    if (plansRef.current) {
      window.scrollTo({
        top: plansRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <div className={styles.container}>
      <Introduction handleNavigateToPlans={handleNavigateToPlans} />

      <Plans ref={plansRef} />

      <Reviews />

      <Questions />
    </div>
  );
}
