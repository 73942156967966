import React, { FunctionComponent, useMemo } from "react";
import { AxiosError } from "axios";
import { Bounce, ToastContainer, toast } from "react-toastify";

// helpers
import { unknownToError } from "./error";

// types
import type { ErrorResponse } from "./ErrorProvider.types";

// styles
import "react-toastify/dist/ReactToastify.css";

type ErrorContextType = {
  error: (rawError: unknown) => void;
  success: (message: string) => void;
  info: (message: string) => void;
};

type ErrorProviderProps = {
  children: JSX.Element;
};

export const errorContext = React.createContext({} as ErrorContextType);

export const ErrorProvider: FunctionComponent<ErrorProviderProps> = (props) => {
  const { children } = props;

  const error = (rawError: unknown): null => {
    if (rawError instanceof AxiosError) {
      const customError = rawError.response?.data
        ? rawError.response?.data
        : rawError.message;

      if (Array.isArray(customError.message)) {
        toast.error(customError.message[0], { role: "client" });
        return null;
      }

      toast.error(customError.message, { role: "client" });
      return null;
    }

    const error = unknownToError(rawError);
    if (Array.isArray(error.message)) {
      toast.error(error.message[0], { role: "client" });
      return null;
    }

    toast.error(error.message, { role: "client" });
    return null;
  };

  const success = (message: string): void => {
    toast.success(message, { role: "client" });
  };

  const info = (message: string): void => {
    toast.info(message, { role: "client" });
  };

  const contextValue = useMemo(() => ({ error, success, info }), []);

  return (
    <errorContext.Provider value={contextValue}>
      {children}
      <ToastContainer
        role="client"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </errorContext.Provider>
  );
};
