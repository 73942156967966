import { useContext, useEffect, useMemo, useState } from "react";

// hooks
import { useWindowSize } from "../../../hooks/use-window-size/use-window-size";

// context
import { userContext } from "../../../context/user-provider/UserProvider";
import { courseContext } from "../../../context/course-provider/CourseProvider";
import { errorContext } from "../../../context/error/ErrorProvider";

// types
import type {
  CourseType,
  VideoType,
} from "../../../context/course-provider/CourseProvider.types";

export function useMyCoursesFetch(type?: string) {
  const { error } = useContext(errorContext);
  const { coursesData, myCoursesData, getMyCourses, isCoursesLoading } =
    useContext(courseContext);

  const [isProfileLoading, setIsProfileLoading] = useState(true);

  const [myBeginnerCoursesData, setMyBeginnerCoursesData] = useState<
    CourseType[]
  >([]);
  const [myProCoursesData, setMyProCoursesData] = useState<CourseType[]>([]);
  const [myVipCoursesData, setMyVipCoursesData] = useState<CourseType[]>([]);

  const [beginnerCoursesData, setBeginnerCoursesData] = useState<CourseType[]>(
    []
  );
  const [vipCoursesData, setVipCoursesData] = useState<CourseType[]>([]);
  const [proCoursesData, setProCoursesData] = useState<CourseType[]>([]);

  useEffect(() => {
    const beginnerCourses = myCoursesData?.filter(
      (course) => course.category === "beginner"
    );

    if (beginnerCourses) {
      setMyBeginnerCoursesData(beginnerCourses);
    }

    const proCourses = myCoursesData?.filter(
      (course) => course.category === "pro"
    );

    if (proCourses) {
      setMyProCoursesData(proCourses);
    }

    const vipCourses = myCoursesData?.filter(
      (course) => course.category === "vip"
    );

    if (vipCourses) {
      setMyVipCoursesData(vipCourses);
    }
  }, [myCoursesData]);

  useEffect(() => {
    const beginnerCourses = coursesData?.filter(
      (course) => course.category === "beginner"
    );

    if (beginnerCourses) {
      setBeginnerCoursesData(beginnerCourses);
    }

    const vipCourses = coursesData?.filter(
      (course) => course.category === "vip"
    );

    if (vipCourses) {
      setVipCoursesData(vipCourses);
    }
    const proCourses = coursesData?.filter(
      (course) => course.category === "pro"
    );

    if (proCourses) {
      setProCoursesData(proCourses);
    }
  }, [coursesData]);

  const getMyCoursesData = async () => {
    try {
      setIsProfileLoading(true);

      await getMyCourses();
    } catch (err) {
      error(err);
    } finally {
      setIsProfileLoading(false);
    }
  };

  useEffect(() => {
    if (!type) {
      getMyCoursesData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMyCoursesLoading = isProfileLoading || isCoursesLoading;

  return {
    isMyCoursesLoading,
    myCoursesData,
    myBeginnerCoursesData,
    myProCoursesData,
    myVipCoursesData,
    beginnerCoursesData,
    proCoursesData,
    vipCoursesData,
  };
}
