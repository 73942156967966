import React from "react";

// icons
import { StarIcon } from "../../../../assets/icons/StarIcon";

// types
import type { ReviwersDataType } from "../reviews/Review.types";

// styles
import styles from "./ReviewItem.module.scss";

type ReviewItemProps = {
  review: ReviwersDataType;
};

export default function ReviewItem({ review }: ReviewItemProps) {
  const stars = Array.from({ length: 4 }, (_, index) => {
    if (index < review.rating - 1) {
      return <StarIcon key={index} />;
    } else {
      return <StarIcon color="#f4f4f4" key={index} />;
    }
  });

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <img
          className={styles.reviewerImage}
          src={review.avatar_src}
          alt={`${review.name}-review`}
        />

        <div className={styles.reviewerName}>
          {review.name} {review.surname}
        </div>
      </div>

      <div className={styles.rating}>
        <StarIcon />
        {stars}
      </div>

      <div className={styles.reviewWrapper}>
        <p className={styles.reviewText}>{review.reviewText}</p>
        <p className={styles.date}>{review.date}</p>
      </div>
    </div>
  );
}
