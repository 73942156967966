//consts
import { PATHS } from "../../route/route.controls";

// context
import { useContext } from "react";
import { localesContext } from "../../context/local-provider/LocalProvider";

//translation
import { t } from "@lingui/macro";

export function useAdminLayoutConsts() {
  const { i18n } = useContext(localesContext);

  const ADMIN_TABS = [
    {
      title: t(i18n)`Membership plans`,
      to: PATHS.adminMemberships,
    },
    {
      title: t(i18n)`Blog`,
      to: PATHS.adminBlog,
    },
    {
      title: t(i18n)`Users`,
      to: PATHS.adminUsers,
    },
    {
      title: t(i18n)`Courses`,
      to: PATHS.adminCourses,
    },
  ];

  return { ADMIN_TABS };
}
